import React from "react";
import "./ourServicesContainer.css";
import greenBullet from "../../assets/images/greenBullet.svg";
import blueBullet from "../../assets/images/blueBullet.svg";
import pinkBullet from "../../assets/images/pinkBullet.svg";
import yellowBullet from "../../assets/images/yellowBullet.svg";
import referral from "../../assets/images/service-1.png";
import service2 from "../../assets/images/service-2.png";
import service3 from "../../assets/images/service-3.png";
import service4 from "../../assets/images/service-4.png";

const OurServicesContainer = () => {
  const servicesData = [
    {
      heading: "Receive a",
      colourHeading: "Job Referral",
      des: "Unlock exclusive job opportunities by connecting with employees who can refer you to positions within their companies. Our platform makes it easy to find and connect with insiders who can vouch for your skills and experience, significantly increasing your chances of landing your dream job.",
      servicesImg: referral,
      colour: "#1AD079",
      textColour: "#0B0B1B",
      benefits: [
        {
          point: "Access to unlisted job opportunities",
          bullet: greenBullet,
        },
        {
          point: "Increased chances of getting noticed by hiring managers",
          bullet: greenBullet,
        },
        {
          point: "Personalized support from company insiders",
          bullet: greenBullet,
        },
      ],
    },
    {
      heading: "Refer ",
      colourHeading: "Other People",
      des: "Join Konect as a referee and help talented job seekers by referring them to opportunities within your organization. Not only do you get to support others in their career paths, but you also earn rewards for successful referrals.",
      servicesImg: service2,
      colour: "#3A37D6",
      textColour: "#F8F8F8",
      benefits: [
        {
          point: "Help shape the future of promising professionals",
          bullet: blueBullet,
        },
        {
          point: "Earn rewards for successful referrals",
          bullet: blueBullet,
        },
        {
          point: "Strengthen your professional network",
          bullet: blueBullet,
        },
      ],
    },
    // {
    //   heading: "Receive ",
    //   colourHeading: "Career Advice",
    //   des: "Gain insights and guidance from industry experts to navigate your career path effectively. Whether you’re looking for advice on job search strategies, career transitions, or professional development, our advisors are here to help.",
    //   servicesImg: service3,
    //   colour:'#D01A71',
    //   textColour:'#F8F8F8',
    //   benefits: [
    //     {
    //       point: "Personalized career advice tailored to your goals",
    //       bullet: pinkBullet,
    //     },
    //     {
    //       point: "Expert insights on industry trends and job market",
    //       bullet: pinkBullet,
    //     },
    //     {
    //       point: "Support for career transitions and growth",
    //       bullet: pinkBullet,
    //     },
    //   ],
    // },
    {
      heading: "CV & Cover Letter  ",
      colourHeading: "Assistance",
      des: "Join Konect as a referee and help talented job seekers by referring them to opportunities within your organization. Not only do you get to support others in their career paths, but you also earn rewards for successful referrals.",
      servicesImg: service4,
      colour: "#CCD01A",
      textColour: "#0B0B1B",
      benefits: [
        {
          point: "Help shape the future of promising professionals",
          bullet: yellowBullet,
        },
        {
          point: "Earn rewards for successful referrals",
          bullet: yellowBullet,
        },
        {
          point: "Strengthen your professional network",
          bullet: yellowBullet,
        },
      ],
    },
  ];

  return (
    <div className="main-our-services-container">
      {servicesData.map((data, index) => (
        <div key={index} className="our-services-container">
          <div>
            <div className="our-services-container-heading">
              {data.heading}{" "}
              <span style={{ color: data.colour }}>{data.colourHeading}</span>
            </div>
            <div>{data.des}</div>
            <div className="our-services-sub-container">Benefits</div>
            <div className="our-services-points-container">
              {data.benefits.map((benefit, index) => (
                <div key={index} className="benefit-point-container">
                  <img
                    src={benefit.bullet}
                    alt="bullet"
                    className="bullet-icon"
                  />
                  <div>{benefit.point}</div>
                </div>
              ))}
            </div>
            <button
              className="services-button-common"
              style={{ backgroundColor: data.colour, color: data.textColour }}
            >
              Request a Referral
            </button>
          </div>
          <div>
            <img
              src={data.servicesImg}
              alt="servicesImg"
              className="our-services-image"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default OurServicesContainer;
