import React, { useState } from "react";
import "./AccountSetting.css";
import profile from "../../../assets/images/adminLayout/Dashboard/profile2.jpg";
import CustomButton from "../../../components/CustomButton/CustomButton";
import wallArt from "../../../assets/images/UserLayout/Icons/wall_art.svg";
import saveIcon from "../../../assets/images/UserLayout/Icons/save.svg";
import keyIcon from "../../../assets/images/UserLayout/Icons/key.svg";

const AccountSetting = ({ privilege }) => {
  const userData = JSON.parse(localStorage.getItem("user"));

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    workPhone: "",
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [passwordErrors, setPasswordErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear the error on input change
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
    setPasswordErrors({ ...passwordErrors, [name]: "" }); // Clear the error on input change
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.phone) newErrors.phone = "Phone number is required.";
    if (!formData.email) newErrors.email = "E-Mail is required.";
    if (!formData.workPhone)
      newErrors.workPhone = "Work phone number is required.";
    return newErrors;
  };

  const validatePasswordForm = () => {
    const newErrors = {};
    if (!passwordData.currentPassword)
      newErrors.currentPassword = "Current password is required.";
    if (!passwordData.newPassword)
      newErrors.newPassword = "New password is required.";
    if (!passwordData.confirmPassword)
      newErrors.confirmPassword = "Confirm password is required.";
    else if (passwordData.newPassword !== passwordData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match.";
    return newErrors;
  };

  const handleUpdateClick = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      console.log("Form submitted successfully", formData);
      // Perform the update logic here
    }
  };

  const handleChangePasswordClick = () => {
    const validationErrors = validatePasswordForm();
    if (Object.keys(validationErrors).length > 0) {
      setPasswordErrors(validationErrors);
    } else {
      console.log("Password updated successfully", passwordData);
      // Perform the password update logic here
    }
  };

  return (
    <div className="account-setting-container">
      <h5 className="heading-font-weight">My Profile</h5>
      <h6 className="heading-font-weight">Personal Information</h6>
      <div className="setting-des">
        Keep your personal information accurate to ensure seamless communication
        and account access.
      </div>
      <div className="setting-details-container">
        <div className="profile-container">
          <img src={profile} className="setting-profile-pic" alt="Profile" />
          {privilege !== "user" ? (
            <>
              <CustomButton
                label="Browse"
                onClick={() => console.log("Browse clicked!")}
                type="dashboard-button gray-button"
              />
              <CustomButton
                label="Update"
                onClick={handleUpdateClick}
                type="dashboard-button green-button"
              />
            </>
          ) : (
            <>
              <button className="button-common user-dashboard-table-button user-setting-button pay-cancel-button">
                <img
                  src={wallArt}
                  className="user-dashboard-table-button-icon"
                  alt="Wall Art"
                />
                Browse
              </button>

              <button
                className="button-common user-dashboard-table-button user-setting-button"
                onClick={handleUpdateClick}
              >
                <img
                  src={saveIcon}
                  className="user-dashboard-table-button-icon"
                  alt="Save Icon"
                />
                Update
              </button>
            </>
          )}
        </div>
        <div className="personal-information">
          <div className="contact-form-sub-heading heading-font-weight">
            Name
          </div>
          <input
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="contact-form-input setting-form-input"
            placeholder="Enter your Name"
          />
          {errors.name && <div className="error-message">{errors.name}</div>}
          <div className="contact-form-sub-heading heading-font-weight">
            Phone Number
          </div>
          <input
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="contact-form-input setting-form-input"
            placeholder="Enter your Phone No"
          />
          {errors.phone && <div className="error-message">{errors.phone}</div>}
        </div>
        <div className="personal-information">
          <div className="contact-form-sub-heading heading-font-weight">
            E-Mail Address
          </div>
          <input
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="contact-form-input setting-form-input"
            placeholder="Enter your E-Mail"
          />
          {errors.email && <div className="error-message">{errors.email}</div>}
          <div className="contact-form-sub-heading heading-font-weight">
            Phone Number (Work)
          </div>
          <input
            name="workPhone"
            value={formData.workPhone}
            onChange={handleInputChange}
            className="contact-form-input setting-form-input"
            placeholder="Enter your Phone No"
          />
          {errors.workPhone && (
            <div className="error-message">{errors.workPhone}</div>
          )}
        </div>
      </div>

      <hr className="account-setting-hr" />
      <h5 className="heading-font-weight">Security</h5>
      <h6 className="heading-font-weight">Password</h6>
      <div className="setting-des">
        Update your password regularly to protect your personal information and
        ensure account safety
      </div>
      <div className="setting-details-container">
        <div className="personal-information">
          <div className="contact-form-sub-heading heading-font-weight">
            Current Password
          </div>
          <input
            name="currentPassword"
            value={passwordData.currentPassword}
            onChange={handlePasswordChange}
            className="contact-form-input setting-form-password-input"
            placeholder="Current Password"
          />
          {passwordErrors.currentPassword && (
            <div className="error-message">
              {passwordErrors.currentPassword}
            </div>
          )}
          <div className="personal-information-password">
            <div>
              <div className="contact-form-sub-heading heading-font-weight">
                New Password
              </div>
              <input
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handlePasswordChange}
                className="contact-form-input setting-form-password-input"
                placeholder="New Password"
              />
              {passwordErrors.newPassword && (
                <div className="error-message">
                  {passwordErrors.newPassword}
                </div>
              )}
            </div>

            <div>
              <div className="contact-form-sub-heading heading-font-weight">
                Confirm Password
              </div>
              <input
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handlePasswordChange}
                className="contact-form-input setting-form-password-input"
                placeholder="Confirm Password"
              />
              {passwordErrors.confirmPassword && (
                <div className="error-message">
                  {passwordErrors.confirmPassword}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {privilege !== "user" ? (
        <CustomButton
          label="Change Password"
          onClick={handleChangePasswordClick}
          type="dashboard-button green-button"
        />
      ) : (
        <button
          className="button-common user-dashboard-table-button"
          onClick={handleChangePasswordClick}
        >
          <img
            src={keyIcon}
            className="user-dashboard-table-button-icon"
            alt="Key Icon"
          />
          Change Password
        </button>
      )}
    </div>
  );
};

export default AccountSetting;
