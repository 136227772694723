import React from "react";
import "./Home.css";
import SuccessServices from "../containers/OurServices/SuccessServices";
import OurServicesContainer from "../containers/OurServices/ourServicesContainer";
import UpdatedWith from "../containers/OurServices/UpdatedWith";
const OurServices = () => {
  return (
    <>
      <div className="main-header-heading-condatiner">
        <h1>Our Services</h1>
        <p>
          Connecting You to Opportunities and Providing Expert Career Support
        </p>
      </div>
      <div className="main-home-container">
        <SuccessServices />
        <OurServicesContainer />
        <UpdatedWith />
      </div>
    </>
  );
};

export default OurServices;
