import React, { useEffect, useState } from "react";
import TitlePath from "../../../../components/TitlePath/TitlePath";
import { useNavigate, useParams } from "react-router-dom";
import chatIcon from "../../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import resumeIcon from "../../../../assets/images/adminLayout/Dashboard/Icons/resumeIcon.svg";
import coverLetterIcon from "../../../../assets/images/adminLayout/Dashboard/Icons/coverLetterIcon.svg";
import "./UserResumeReviewDetails.css";
import arrowBack from "../../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import saveIcon from "../../../../assets/images/UserLayout/Icons/save_icon.svg";
import closeIcon from "../../../../assets/images/UserLayout/Icons/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { getResumeReviewById } from "../../../../redux/actions/jobSeekerAction";
import moment from "moment";
import {
  getResumeReviewData,
  updateResumeReview,
} from "../../../../services/jobSeeker/jobSeekerService";
import ChatModal from "../../../AdminLayoutPage/ChatModal/ChatModal";

const UserResumeReviewDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const resumeReviewDetail = useSelector(
    (state) => state.jobSeeker.selectedResumeReview || null,
  );
  const [feedback, setFeedback] = useState("");
  const [showFeedback, setShowFeedback] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBack = () => {
    navigate(`/user_dashboard`);
  };
  const handleBackTable = () => {
    navigate(`/user_dashboard/resume_review`);
  };

  const closeChat = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (id) {
      console.log("Dispatching getResumeReview with ID:", id);
      dispatch(getResumeReviewData(id));
    }
  }, [dispatch, id]);

  const handleFeedbackSubmit = async () => {
    try {
      await dispatch(updateResumeReview(id, { feedback }));
      setFeedback("");
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };
  const handleChatBtn = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setShowFeedback(false);
    setFeedback("");
  };

  const titlePath = {
    exactPath: `Amelia Clarke-JR45845`,
    paths: [
      { label: "Services >", onClick: handleBack },
      { label: "Resume Review > ", onClick: handleBackTable },
    ],
  };
  return (
    <div className="main-dashboard-container user-job-referrals-details-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="jra-container">
        <div className="jra-header">
          <div className="jra-header-title">
            <h4 className="jra-header-heading">
              Resume & Cover Letter Review & Assistance
            </h4>
          </div>
          <div className="jra-header-actions">
            {/* <select className="jra-status-select">
              <option>Mark Status</option>
            </select> */}
            <button className="jra-chat-button" onClick={handleChatBtn}>
              <img src={chatIcon} className="chatIcon" />
              Chat
              {/* <span className="jra-notification-badge">15</span> */}
            </button>
          </div>
        </div>

        <p className="user-resume-details-des">
          Get expert feedback on your resume and cover letter to improve your
          chances of landing the job you want. Submit a request and receive
          personalized assistance.{" "}
        </p>

        {/* Application Status */}
        <h5 className="jra-heading">Application Status</h5>
        <div className="jra-card">
          <div className="jra-status-row">
            <div>
              <p className="jra-status-label">Name</p>
              <p className="jra-status-value">
                {resumeReviewDetail?.jobSeekerDetails?.name}
              </p>
            </div>
            <div>
              <div>
                <p className="jra-status-label">Submission Date</p>
                <p className="jra-status-value">
                  {moment(resumeReviewDetail?.submissionDate).format(
                    "YYYY-MM-DD",
                  )}
                </p>
              </div>
              <div>
                <p className="jra-status-label">Status</p>
                <span className="jra-status-badge">
                  {resumeReviewDetail?.currentStatus}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* User Information */}
        <h5 className="jra-heading">User Information</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">E-Mail Address</p>
              <p className="jra-status-value">
                {resumeReviewDetail?.jobSeekerDetails?.email}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number</p>
              <p className="jra-status-value">
                {resumeReviewDetail?.jobSeekerDetails?.mobile}
              </p>
            </div>
          </div>
        </div>

        {/* Industry Selection */}
        <h5 className="jra-heading">Industry Selection</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Industry</p>
              <p className="jra-status-value">
                {resumeReviewDetail?.industryDetails?.industry}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Desired Position</p>
              <p className="jra-status-value">
                {resumeReviewDetail?.industryDetails?.desiredPosition}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Desired Company</p>
              <p className="jra-status-value">
                {resumeReviewDetail?.industryDetails?.desiredCompany}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Current Job</p>
              <p className="jra-status-value">
                {resumeReviewDetail?.industryDetails?.currentJob}
              </p>
            </div>
          </div>
        </div>

        {/* Application Details */}
        <h5 className="jra-heading">Application Details</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Resume</p>
              <a
                href={`https://konect.sktgold.com/${resumeReviewDetail?.resume}`}
                target="_blank"
                className="jra-file-link "
              >
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={resumeIcon} />
                {`https://konect.sktgold.com/${resumeReviewDetail?.resume}`}
              </a>
            </div>
            <div>
              <p className="jra-status-label">Cover Letter</p>
              <a
                href={`https://konect.sktgold.com/${resumeReviewDetail?.coverLetter}`}
                target="_blank"
                className="jra-file-link "
              >
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={coverLetterIcon} />
                {`https://konect.sktgold.com/${resumeReviewDetail?.coverLetter}`}{" "}
              </a>
            </div>
          </div>
        </div>

        {/*Review*/}
        <h5 className="jra-heading">Review</h5>
        <div className="jra-card">
          <p className="jra-status-label">Submitted Information:</p>
          {/* <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label-colour">AI-powered Reviewed CV</p>
              <a href="#" className="jra-file-link ">
                <img src={resumeIcon} />
                AI_Reviewed_Resume.pdf
              </a>
            </div>
            <div>
              <p className="jra-status-label-colour">
                CV Consultant Reviewed CV
              </p>
              <a href="#" className="jra-file-link ">
                <img src={coverLetterIcon} />
                Consultant_Reviewed_Resume.pdf
              </a>
            </div>
          </div> */}
          <p className="jra-status-label">CV Reviewer Comments</p>
          <p className="jra-status-label-colour">AI-powered Reviewed CV</p>
          <p className="jra-status-label-colour">
            <div className="jra-grid-1-col">
              {resumeReviewDetail?.review?.aiReviewedFeedback
                ?.split("\n")
                .map((line, index) => {
                  line = line.trim();

                  if (line.match(/^(\d+)\./)) {
                    const parts = line.split(":");
                    return (
                      <p key={index}>
                        <strong>{parts[0]}:</strong> {parts[1]}
                      </p>
                    );
                  }

                  if (line !== "") {
                    return <p key={index}>{line}</p>;
                  }

                  return null;
                })}
            </div>
          </p>
        </div>

        {/* Payment Details */}
        <h5 className="jra-heading">Payment Details</h5>
        <div className="jra-card">
          <div className="payment-received-details">
            <h3 className="payment-subtitle">Payment Received Details</h3>
            <div className="payment-row">
              <span className="payment-label">Reference</span>
              <span className="payment-value">
                {resumeReviewDetail?.payment?._id}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Date</span>
              <span className="payment-value">
                {moment(resumeReviewDetail?.payment?.date).format("YYYY-MM-DD")}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Method</span>
              <span className="payment-value">
                {resumeReviewDetail?.payment?.paymentMethod}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Amount</span>
              <span className="payment-value">
                {resumeReviewDetail?.payment?.amount}
              </span>
            </div>
          </div>
        </div>

        {/* Feedback */}
        {showFeedback && (
          <div>
            {/* Feedback */}
            <h5 className="jra-heading">Feedback</h5>
            <div className="jra-card">
              <textarea
                className="contact-form-textarea user-request-form"
                placeholder="Enter your feedback here..."
                rows="10"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>
            <div className="user-request-form-actions">
              <button
                className="button-common user-dashboard-table-button pay-cancel-button"
                onClick={handleCancel}
              >
                <img
                  src={closeIcon}
                  className="user-dashboard-table-button-icon"
                  alt="Cancel"
                />
                Cancel
              </button>
              <button
                className="button-common user-dashboard-table-button"
                onClick={handleFeedbackSubmit}
              >
                <img
                  src={saveIcon}
                  className="user-dashboard-table-button-icon"
                  alt="Submit"
                />
                Submit
              </button>
            </div>
          </div>
        )}

        {/* <h5 className="jra-heading">Feedback</h5>
        <div className="jra-card">
          <textarea
            className="contact-form-textarea user-request-form"
            placeholder="Enter your feedback here...          "
            rows="10"
          />
        </div>
      </div>
      <div className="user-request-form-actions">
        <button className="button-common user-dashboard-table-button pay-cancel-button">
          <img src={closeIcon} class="user-dashboard-table-button-icon" />
          Cancel
        </button>
        <button className="button-common user-dashboard-table-button">
          <img src={saveIcon} class="user-dashboard-table-button-icon" />
          Submit
        </button> */}
      </div>
      <ChatModal
        isOpen={isModalOpen}
        onClose={closeChat}
        requestId={id}
        jobReferralDetail={resumeReviewDetail}
        chatType="resumeReview"
      />
    </div>
  );
};
export default UserResumeReviewDetails;
