import React, { useState, useEffect } from "react";
import "./LearningHub.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import learninghubImg1 from "../../assets/images/learninghubImg1.png";
import learninghubImg2 from "../../assets/images/learninghubImg2.png";
import learninghubImg3 from "../../assets/images/learninghubImg3.png";
import learninghubImg4 from "../../assets/images/learninghubImg4.png";
import learninghubImg5 from "../../assets/images/learninghubImg5.png";
import learninghubImg6 from "../../assets/images/learninghubImg6.png";
import doubleArrowRight from "../../assets/images/doubleArrowRight.svg";
import singleArrowRight from "../../assets/images/singleArrowRight.svg";
import { useNavigate } from "react-router-dom";
import ReadMoreArrow from "../../assets/images/read-more-arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import { fetchLearningHubData } from "../../services/LearningHub/LearningHub";

import Pagination from "../../components/Pagination/Pagination";

const LearningHub = () => {
  const dispatch = useDispatch();

   // Redux state
   const { learningHubData, loading, error } = useSelector((state) => state.learninghub);


  const LearningHubData = [
    {
      img: learninghubImg1,
      name: "Mitchell Johnson",
      date: "27 April 2024",
      title: "Mastering Job Interviews: Tips from Top Recruiters",
      des: " Learn how to impress in job interviews with insights from experienced recruiters. Gain strategies to answer common questions, highlight your strengths, and leave a lasting impression.",
    },
    {
      img: learninghubImg2,
      name: "Brett Lee",
      date: "27 April 2024",
      title: "Building a Strong Professional Network Among the Fostering World",
      des: "Discover effective techniques for networking in your industry. This webinar covers online and offline strategies to build and maintain valuable professional relationships.",
    },
    {
      img: learninghubImg3,
      name: "Ricky Ponting",
      date: "27 April 2024",
      title:
        "5 Ways to Enhance Your LinkedIn Profile to Attract Potential Gateways",
      des: "Improve your LinkedIn profile with these simple tips. Learn how to optimize your headline, summary, and experience sections to attract potential employers.",
    },
    {
      img: learninghubImg4,
      name: "Glenn Maxwell",
      date: "27 April 2024",
      title:
        "Managing Work-Life Balance in a Busy World to Keep Your Mental Health",
      des: "Learn practical tips to achieve a better work-life balance. This article offers advice on time management, setting boundaries, and prioritizing self-care.",
    },
    {
      img: learninghubImg5,
      name: "Pat Cummins",
      date: "27 April 2024",
      title:
        "How to Negotiate Your Salary Confidently to Articulate Your Value",
      des: "Gain confidence in salary negotiations with these expert tips. Learn how to research market rates, articulate your value, and negotiate effectively.",
    },
    {
      img: learninghubImg6,
      name: "Mitchell Marsh",
      date: "27 April 2024",
      title:
        "Creating Impactful Resumes and Cover Letters to Stand Out Higher in the Industry World",
      des: "Find out how to craft resumes and cover letters that stand out. This webinar provides practical tips for showcasing your qualifications and making a great first impression.",
    },
    {
      img: learninghubImg1,
      name: "Mitchell Johnson",
      date: "27 April 2024",
      title: "Mastering Job Interviews: Tips from Top Recruiters",
      des: " Learn how to impress in job interviews with insights from experienced recruiters. Gain strategies to answer common questions, highlight your strengths, and leave a lasting impression.",
    },
    {
      img: learninghubImg2,
      name: "Brett Lee",
      date: "27 April 2024",
      title: "Building a Strong Professional Network Among the Fostering World",
      des: "Discover effective techniques for networking in your industry. This webinar covers online and offline strategies to build and maintain valuable professional relationships.",
    },
    {
      img: learninghubImg3,
      name: "Ricky Ponting",
      date: "27 April 2024",
      title:
        "5 Ways to Enhance Your LinkedIn Profile to Attract Potential Gateways",
      des: "Improve your LinkedIn profile with these simple tips. Learn how to optimize your headline, summary, and experience sections to attract potential employers.",
    },
    {
      img: learninghubImg4,
      name: "Glenn Maxwell",
      date: "27 April 2024",
      title:
        "Managing Work-Life Balance in a Busy World to Keep Your Mental Health",
      des: "Learn practical tips to achieve a better work-life balance. This article offers advice on time management, setting boundaries, and prioritizing self-care.",
    },
    {
      img: learninghubImg5,
      name: "Pat Cummins",
      date: "27 April 2024",
      title:
        "How to Negotiate Your Salary Confidently to Articulate Your Value",
      des: "Gain confidence in salary negotiations with these expert tips. Learn how to research market rates, articulate your value, and negotiate effectively.",
    },
    {
      img: learninghubImg6,
      name: "Mitchell Marsh",
      date: "27 April 2024",
      title:
        "Creating Impactful Resumes and Cover Letters to Stand Out Higher in the Industry World",
      des: "Find out how to craft resumes and cover letters that stand out. This webinar provides practical tips for showcasing your qualifications and making a great first impression.",
    },
    {
      img: learninghubImg1,
      name: "Mitchell Johnson",
      date: "27 April 2024",
      title: "Mastering Job Interviews: Tips from Top Recruiters",
      des: " Learn how to impress in job interviews with insights from experienced recruiters. Gain strategies to answer common questions, highlight your strengths, and leave a lasting impression.",
    },
    {
      img: learninghubImg2,
      name: "Brett Lee",
      date: "27 April 2024",
      title: "Building a Strong Professional Network Among the Fostering World",
      des: "Discover effective techniques for networking in your industry. This webinar covers online and offline strategies to build and maintain valuable professional relationships.",
    },
    {
      img: learninghubImg3,
      name: "Ricky Ponting",
      date: "27 April 2024",
      title:
        "5 Ways to Enhance Your LinkedIn Profile to Attract Potential Gateways",
      des: "Improve your LinkedIn profile with these simple tips. Learn how to optimize your headline, summary, and experience sections to attract potential employers.",
    },
    {
      img: learninghubImg4,
      name: "Glenn Maxwell",
      date: "27 April 2024",
      title:
        "Managing Work-Life Balance in a Busy World to Keep Your Mental Health",
      des: "Learn practical tips to achieve a better work-life balance. This article offers advice on time management, setting boundaries, and prioritizing self-care.",
    },
    {
      img: learninghubImg5,
      name: "Pat Cummins",
      date: "27 April 2024",
      title:
        "How to Negotiate Your Salary Confidently to Articulate Your Value",
      des: "Gain confidence in salary negotiations with these expert tips. Learn how to research market rates, articulate your value, and negotiate effectively.",
    },
    {
      img: learninghubImg6,
      name: "Mitchell Marsh",
      date: "27 April 2024",
      title:
        "Creating Impactful Resumes and Cover Letters to Stand Out Higher in the Industry World",
      des: "Find out how to craft resumes and cover letters that stand out. This webinar provides practical tips for showcasing your qualifications and making a great first impression.",
    },
  ];

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  // const totalPage = 12;
  const itemsPerPage = 6;

    // Fetch data when the component mounts
    useEffect(() => {
      dispatch(fetchLearningHubData());
    }, [dispatch]);
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = learningHubData.slice(indexOfFirstItem, indexOfLastItem);

  // const totalPages = Math.ceil(LearningHubData.length / itemsPerPage);
  const totalPages = Math.ceil((learningHubData?.length || 0) / itemsPerPage);

  // const paginate = (pageNumber) => {
  //   if (pageNumber > 0 && pageNumber <= totalPages) {
  //     setCurrentPage(pageNumber);
  //   }
  // };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="main-learninghub-container">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>

      <div>
        {currentItems.map((data) => (
          <div
            // key={data.title}
            key={data._id}
            className="learninghub-container"
            onClick={() => navigate(`/articleview/${data._id}`)} 
          >
            <div className="learninghub-image-container">
              <img
                className="learninghub-image"
                // src={data.img}
                // alt="community-image"
                  src={data.image} // Assuming the image URL is already full
                  alt={data.title}
              />
            </div>
            <div className="learninghub-name-date-container">
              {/* <div>{data.name}</div>
              <div>{data.date}</div> */}
              <div>{data.author}</div>
              <div>{new Date(data.publishedAt).toLocaleDateString()}</div>

            </div>
            {/* <div className="learninghub-title-container"> */}
            <h5 className="learninghub-title-container">{data.title}</h5>
            <p>{data.content.substring(0, 100)}...</p>

            <CustomButton
              // label="Read More →"
              label={
                <span className="get-button-aliment">
                  Read More{" "}
                  <img
                    src={ReadMoreArrow}
                    alt="arrow"
                    className="get-button-icon"
                  />
                </span>
              }
              type="readmore-button"
            />
          </div>
        ))}
      </div>
      {/* <div className="pagination">
        <div className="previous-next-button-icon-container">
          <button
            className="pagination-arrow-button"
            onClick={() => paginate(1)}
            disabled={currentPage === 1}
          >
            <img src={doubleArrowRight} className="previous-next-button-img" />
          </button>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-arrow-button"
          >
            <img src={singleArrowRight} className="previous-next-button-img" />
          </button>
        </div>
        <div>
          {[...Array(totalPages).keys()].map((number) => (
            <button
              key={number + 1}
              onClick={() => paginate(number + 1)}
              className={number + 1 === currentPage ? "active" : ""}
            >
              {number + 1}
            </button>
          ))}
        </div>
        <div className="previous-next-button-icon-container">
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-arrow-button"
          >
            <img
              src={singleArrowRight}
              className="previous-next-button-img"
              style={{ transform: "scaleX(-1)" }}
            />
          </button>
          <button
            className="pagination-arrow-button"
            onClick={() => paginate(totalPages)}
            disabled={currentPage === totalPages}
          >
            <img
              src={doubleArrowRight}
              className="previous-next-button-img"
              style={{ transform: "scaleX(-1)" }}
            />
          </button>
        </div>
      </div> */}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        singleArrowRight={singleArrowRight}
        doubleArrowRight={doubleArrowRight}
      />
      </>
      )}

    </div>
  );
};

export default LearningHub;

