import React from "react";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import webinarImg1 from "../../assets/images/webinarImg1.jpg";
import webinarHost1 from "../../assets/images/webinarHost1.jpg";

const LearningHubWebinarView = () => {
  const navigate = useNavigate();
  const webinarData = [
    {
      heading: "Navigating Job Interviews Successfully",
      des: "Master the art of job interviews in our engaging webinar, 'Navigating Job Interviews Successfully.' Gain essential tips on answering tough questions, showcasing your strengths, and making a lasting impression. Perfect for job seekers at all levels, this session will boost your confidence and help you land your dream job. Join us for practical advice and interactive exercises!",
      desPoints: [
        {
          point:
            "Learn strategies for answering common and challenging interview questions",
        },
        {
          point:
            "Discover techniques to highlight your strengths and achievements",
        },
        {
          point:
            "Build confidence to ace any job interview at any career level",
        },
        {
          point:
            "Q&A session to address your specific interview concerns and queries",
        },
      ],
    },
  ];

  return (
    <div className="main-articleview-container">
      <div className="articleview-back-button-container">
        <CustomButton
          label="← Back to Learning Hub"
          onClick={() => navigate("/learningHub")}
          type="backto-button"
        />
        <div className="articleview-img-container">
          <img
            className="articleview-image"
            src={webinarImg1}
            alt="article-image"
          />
        </div>

        <div className="articleview-des-container webinar-des-container">
          <div>
            <div className="learninghub-name-date-container">
              <div>Mitchell Johnson</div>
              <div>27 April 2024</div>
            </div>
            <h3 className="articleview-heading">{webinarData[0].heading}</h3>
            <p className="articleview-des webinarview-des">
              {webinarData[0].des}
            </p>

            <ul className="webinar-points-container">
              {webinarData[0].desPoints.map((data, index) => (
                <li key={index}>{data.point}</li>
              ))}
            </ul>
          </div>
          <div>
            <div className="webinar-host-heading">Host</div>
            <div className="webinar-host-container">
              <div className="host-img-container">
                <img
                  className="webinar-host-img"
                  src={webinarHost1}
                  alt="host"
                />
              </div>
              <div className="host-details">
                <div>Mitchell Johnson</div>
                <div>Human Resource Manager</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningHubWebinarView;
