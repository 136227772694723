import { showToastMessage } from "../../redux/actions/toastActions";
import { startLoading, stopLoading } from "../../redux/actions/spinnerActions";
import {
  createJobReferralRequest,
  createResumeReviewRequest,
  fetchDashboardSuccess,
  fetchDashboarsdFailure,
  getJobReferralById,
  getJobReferrals,
  getResumeReview,
  getResumeReviewById,
  updateJobSeekerFeedback,
  updateResumeReviewFeedback,
  fetchIndustriesSuccess,
  fetchIndustriesFailure,
  fetchCompaniesSuccess,
  fetchCompaniesFailure,
  fetchAllChatSuccess,
  sendMessageRequest,
  openChatSuccess,
  fetchAdminJobReferrals,
  getAdminJobReferralById,
  fetchAdminResumeReview,
  getAdminResumeReviewById,
  adminUpdateJobReferralStatus,
  adminUpdateResumeReview,
} from "../../redux/actions/jobSeekerAction";
import { apiRequest } from "../../utils/apiRequest";

export const fetchIndustries = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await apiRequest(
      `/api/general/industries`,
      {},
      false,
      dispatch
    );
    if (response.statusCode === 200) {
      dispatch(fetchIndustriesSuccess(response.data)); // Ensure response.data.data is used
    } else {
      throw new Error("Failed to fetch industries");
    }
  } catch (error) {
    dispatch(fetchIndustriesFailure(error.message));
  } finally {
    dispatch(stopLoading());
  }
};

export const fetchCompanies = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await apiRequest(
      `/api/general/companies`,
      {},
      false,
      dispatch
    );
    if (response.statusCode === 200) {
      dispatch(fetchCompaniesSuccess(response.data)); // Ensure response.data.data is used
    } else {
      throw new Error("Failed to fetch companies");
    }
  } catch (error) {
    dispatch(fetchCompaniesFailure(error.message));
  } finally {
    dispatch(stopLoading());
  }
};

// Helper function to dispatch error messages
const handleApiError = (dispatch, error, errorMessage) => {
  console.error(errorMessage, error);
  dispatch(showToastMessage(errorMessage, "error"));
};

// Fetch job seeker list
export const fetchDashboard = (queryParams = {}) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await apiRequest(
        `/api/job-referral/job-seeker/dashboard`,
        {},
        false,
        dispatch
      );
      //pass response not response.data
      console.log(response);

      dispatch(fetchDashboardSuccess(response.data));
      // dispatch(showToastMessage("JobSeeker fetched successfully!", "success"));
    } catch (error) {
      dispatch(fetchDashboarsdFailure(error));
      handleApiError(dispatch, error, "Failed to fetch JobSeeker.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getJobReferral = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    console.log("Fetching job referral with ID:", id);
    try {
      const response = await apiRequest(
        `/api/job-referral/job-seeker/${id}`,
        {},
        false,
        dispatch
      );
      console.log("API Response:", response.data);
      dispatch(getJobReferralById(response.data));
      // dispatch(showToastMessage("Job referral retrieved successfully!", "success"));
    } catch (error) {
      console.error("API Error:", error);
      handleApiError(dispatch, error, "Failed to retrieve job referral.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Update job seeker by ID
export const updateFeedback = (id, jobSeekerFeedback) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const updatedJobSeeker = await apiRequest(
        `/api/job-referral/job-seeker/${id}/feedback`,
        {
          method: "PUT",
          body: JSON.stringify(jobSeekerFeedback),
        },
        true,
        dispatch
      );
      dispatch(updateJobSeekerFeedback(updatedJobSeeker));
      dispatch(
        showToastMessage("Jobseeker feedback updated successfully!", "success")
      );
    } catch (error) {
      handleApiError(dispatch, error, "Failed to update jobseeker feedback.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get job referrals
export const fetchJobReferrals = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const jobReferralData = await apiRequest(
        `/api/job-referral/job-seeker`,
        {},
        false,
        dispatch
      ); // api call
      console.log("Job Referral Data :", jobReferralData);

      dispatch(getJobReferrals(jobReferralData || [])); //action call
      // dispatch(showToastMessage("Job Referrals retrieved successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to retrieve job referrals.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Add new job referral
export const addJobReferralRequest = (jobReferralData) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(jobReferralData).forEach((key) => {
        formData.append(key, jobReferralData[key]);
      });

      const response = await apiRequest(
        "/api/job-referral/job-seeker",
        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch
      );

      console.log(response.data);
      dispatch(createJobReferralRequest(response.data));
      dispatch(showToastMessage("Job Referral added successfully!", "success"));
      return { status: "success", data: response };
    } catch (error) {
      handleApiError(dispatch, error, JSON.parse(error.message).message);
      return { status: "error", error };
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get job referrals
export const fetchResumeReview = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resumeReviewData = await apiRequest(
        `/api/resume-review/job-seeker`,
        {},
        false,
        dispatch
      );
      console.log("Resume Review Data :", resumeReviewData);

      dispatch(getResumeReview(resumeReviewData));
      // dispatch(showToastMessage("Resume Review data retrieved successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to retrieve resume review");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Add new resume request
export const addResumeReviewRequest = (jobReferralData) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(jobReferralData).forEach((key) => {
        formData.append(key, jobReferralData[key]);
      });

      const response = await apiRequest(
        "/api/resume-review/job-seeker",
        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch
      );

      console.log(response.data);
      dispatch(createResumeReviewRequest(response.data));
      dispatch(
        showToastMessage("Resume Review added successfully!", "success")
      );
      return { status: "success", data: response };
    } catch (error) {
      console.log(JSON.parse(error.message).message);
      
      handleApiError(dispatch, error, JSON.parse(error.message).message);
      return { status: "error", error };
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getResumeReviewData = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    console.log("Fetching resume  review with ID:", id);
    try {
      const response = await apiRequest(
        `/api/resume-review/job-seeker/${id}`,
        {},
        false,
        dispatch
      );
      console.log("API Response:", response.data);
      dispatch(getResumeReviewById(response.data));
      dispatch(
        showToastMessage("Resume Review retrieved successfully!", "success")
      );
    } catch (error) {
      console.error("API Error:", error);
      handleApiError(dispatch, error, "Failed to retrieve resume review.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Update resume review by ID
export const updateResumeReview = (id, resumeReviewFeedback) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const updatedResumeReview = await apiRequest(
        `/api/resume-review/job-seeker/${id}/feedback`,
        {
          method: "PUT",
          body: JSON.stringify(resumeReviewFeedback),
        },
        true,
        dispatch
      );
      dispatch(updateResumeReviewFeedback(updatedResumeReview));
      dispatch(
        showToastMessage(
          "Resume Review feedback updated successfully!",
          "success"
        )
      );
    } catch (error) {
      handleApiError(
        dispatch,
        error,
        "Failed to update resume review feedback."
      );
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Fetch all chat list
export const fetchAllChat = () => {
  return async (dispatch) => {
    console.log("service start to call");

    dispatch(startLoading());
    try {
      const response = await apiRequest(`/api/chat`, {}, false, dispatch);
      if (response.statusCode === 200) {
        dispatch(fetchAllChatSuccess(response.data));
        // dispatch(showToastMessage("Chats fetched successfully!", "success"));
      }
    } catch (error) {
      console.error("Failed to fetch chats", error);
      handleApiError(dispatch, error, "Failed to fetch chats.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Fetch chat by id
export const openChat = ({ chatType, requestId }) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await apiRequest(
        `/api/chat/${chatType}/${requestId}`,
        {},
        false,
        dispatch
      );

      console.log("Fetched chat:", response);
      dispatch(openChatSuccess(response.data));
      // dispatch(showToastMessage("Chat fetched successfully!", "success"));
    } catch (error) {
      console.error("Failed to Chat :", error);
      handleApiError(dispatch, error, "Failed to fetch chat.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Add new message
export const sendMessage = (message) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(message).forEach((key) => {
        formData.append(key, message[key]);
      });

      const response = await apiRequest(
        `/api/chat/${message.chatId}/sendMessage`,
        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch
      );
      const newMessage = response.data.newMessage;
      console.log(newMessage);

      dispatch(sendMessageRequest(newMessage));
      // dispatch(showToastMessage("Message added successfully!", "success"));
      return { status: "success", data: response };
    } catch (error) {
      handleApiError(dispatch, error, "Failed to add message");
      return { status: "error", error };
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Fetch admin job seeker list
export const fetchAdminJobReferralsAsyn = (queryParams = {}) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await apiRequest(
        `/api/admin/services/job-referral`,
        {},
        false,
        dispatch
      );
      //pass response not response.data
      console.log(response);

      dispatch(fetchAdminJobReferrals(response.data));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to fetch JobReferral.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getAdminJobReferral = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    console.log("Fetching job referral with ID:", id);
    try {
      const response = await apiRequest(
        `/api/admin/services/job-referral/${id}`,
        {},
        false,
        dispatch
      );
      console.log("API Response:", response.data);
      dispatch(getAdminJobReferralById(response.data));
    } catch (error) {
      console.error("API Error:", error);
      handleApiError(dispatch, error, "Failed to retrieve job referral.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Fetch admin resume review list
export const fetchAdminResumeReviewAsyn = (queryParams = {}) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await apiRequest(
        `/api/admin/services/resume-review`,
        {},
        false,
        dispatch
      );
      //pass response not response.data
      console.log(response);

      dispatch(fetchAdminResumeReview(response.data));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to fetch Resume Review.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getAdminResumeReview = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    console.log("Fetching resume review with ID:", id);
    try {
      const response = await apiRequest(
        `/api/admin/services/resume-review/${id}`,
        {},
        false,
        dispatch
      );
      console.log("API Response:", response.data);
      dispatch(getAdminResumeReviewById(response.data));
    } catch (error) {
      console.error("API Error:", error);
      handleApiError(dispatch, error, "Failed to retrieve job referral.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Update resume review by ID
export const adminUpdateJobReferralStatusAyns = (id, status) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const updatedResumeReview = await apiRequest(
        `/api/admin/services/job-referral/${id}/`,
        {
          method: "PUT",
          body: JSON.stringify(status),
        },
        true,
        dispatch
      );
      dispatch(adminUpdateJobReferralStatus(updatedResumeReview));
      dispatch(
        showToastMessage("Job Referral status updated successfully!", "success")
      );
    } catch (error) {
      handleApiError(dispatch, error, "Failed to update Job Referral status");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Update ADMIN resume review by ID
export const adminUpdateResumeReviewAysn = (id, resumeReview) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(resumeReview).forEach((key) => {
        formData.append(key, resumeReview[key]);
      });

      const updatedResumeReview = await apiRequest(
        `/api/admin/services/resume-review/${id}`,
        {
          method: "PUT",
          body: formData,
        },
        false,
        dispatch
      );
      dispatch(adminUpdateResumeReview(updatedResumeReview.data));
      dispatch(
        showToastMessage("Resume Review updated successfully!", "success")
      );
      return { status: "success", data: updatedResumeReview };
    } catch (error) {
      handleApiError(dispatch, error, "Failed to update resume review.");
    } finally {
      dispatch(stopLoading());
    }
  };
};
