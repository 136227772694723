import React, { useEffect, useRef, useState } from "react";
import "./SignInModal.css";
import signInImg from "../../assets/images/signInModalImage.png";
import signInOtpImg from "../../assets/images/signInModalImageOtp.png";
import appleIcon from "../../assets/images/Icons/SocialMediaIcons/apple-logo.svg";
import googleIcon from "../../assets/images/Icons/SocialMediaIcons/google-logo.svg";
import linkedinIcon from "../../assets/images/Icons/SocialMediaIcons/linkedin-logo.svg";
import {
  createPasswordAsync,
  createUserAsync,
  forgotPasswordCreatePasswordAsync,
  forgotPasswordSendOTPAsync,
  forgotPasswordVerifyOTPAsync,
  loginAsync,
  resendOTPAsync,
  verifyOTPAsync,
} from "../../services/auth/authService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    // <div className="main-konect-modal-container">
    <div className="konect-modal-overlay" onClick={onClose}>
      <div
        className="konect-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        {/* <button className="konect-modal-close" onClick={onClose}>
            X
          </button> */}
        {children}
      </div>
    </div>
    // </div>
  );
};

const SignInModal = ({
  isOpen,
  onClose,
  isLoginModal,
  setIsLoginModal,
  isCreatePassword,
  setIsCreatePassword,
  otpVarification,
  setOtpVarification,
  setIsOpen,
  otp,
  setOtp,
  isForgotPassword,
  setIsForgotPassword,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [timer, setTimer] = useState(0);
  const [isForgotPasswordOTP, setIsForgotPasswordOTP] = useState(false);
  const [isForgotPasswordConfirm, setIsForgotPasswordConfirm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    userRole: "",
    dateOfBirth: "",
  });

  const hanldeSignModalOpen = () => {
    setIsLoginModal(true);
    setIsCreatePassword(false);
  };
  const hanldeSignUpModalOpen = () => {
    setIsLoginModal(false);
    setIsCreatePassword(false);
    setIsForgotPassword(false);
    setOtpVarification(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    setEmail(formData.email);
    dispatch(createUserAsync(formData, setOtpVarification, setTimer, setOtp));
  };

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleOtpVerify = (e) => {
    e.preventDefault();
    const otpData = otp.join("");
    console.log("Entered OTP:", otpData);

    dispatch(
      verifyOTPAsync(
        otpData,
        setOtp,
        setOtpVarification,
        setIsCreatePassword,
        setTimer,
      ),
    );
  };

  const handleResendCode = (e) => {
    e.preventDefault();
    dispatch(resendOTPAsync(setTimer));
  };

  const handleBackToSignUp = (e) => {
    e.preventDefault();
    setOtpVarification(false);
  };

  const handleCreatePassword = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setErrorMessage("Password must contain at least 8 characters.");
    } else if (password !== confirmPassword) {
      setErrorMessage("Passwords must match.");
    } else {
      console.log("p", password);
      console.log("c", confirmPassword);
      dispatch(
        createPasswordAsync(
          password,
          confirmPassword,
          setOtpVarification,
          setIsCreatePassword,
          setIsLoginModal,
        ),
      );
    }
  };

  // const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      console.log(newOtp);

      setErrorMessage("");
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSignin = (e) => {
    e.preventDefault();
    // setIsLoginModal(false);
    // setIsOpen(false);
    console.log(email, loginPassword);
    setEmail("");
    setLoginPassword("");
    dispatch(
      loginAsync(email, loginPassword, setIsLoginModal, setIsOpen, navigate),
    );
  };

  const handleOpenForgotPassword = () => {
    setIsLoginModal(false);
    setIsCreatePassword(false);
    setOtpVarification(false);
    setIsForgotPassword(true);
    setEmail("");
  };

  const handleForgotMailSubmit = async (e) => {
    e.preventDefault();
    // setIsForgotPasswordOTP(true)
    console.log("otp", otp);
    await dispatch(
      forgotPasswordSendOTPAsync(email, setIsForgotPasswordOTP, setOtp),
    );
  };

  const handleForgotOtpVerify = (e) => {
    e.preventDefault();
    const otpData = otp.join("");
    // setIsForgotPasswordOTP(false)
    // setIsForgotPasswordConfirm(true)
    dispatch(
      forgotPasswordVerifyOTPAsync(
        otpData,
        setIsForgotPasswordOTP,
        setIsForgotPasswordConfirm,
      ),
    );
  };

  const handleForgotCreatePassword = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setErrorMessage("Password must contain at least 8 characters.");
    } else if (password !== confirmPassword) {
      setErrorMessage("Passwords must match.");
    } else {
      // setIsForgotPasswordConfirm(false)
      // setIsOpen(false)
      dispatch(
        forgotPasswordCreatePasswordAsync(
          password,
          confirmPassword,
          setIsOpen,
          setIsForgotPasswordConfirm,
          setIsForgotPasswordOTP,
          setIsForgotPassword,
        ),
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {!otpVarification &&
      !isCreatePassword &&
      !isLoginModal &&
      !isForgotPassword ? (
        // Sign-in form
        <div className="konect-signin-container">
          <div className="signin-modal-img-container">
            <img src={signInImg} className="signin-modal-img" alt="Sign In" />
          </div>
          <div>
            <div className="signin-heading-container">
              <h2 className="konect-modal-title">Join Us Today!</h2>
              <p className="konect-modal-subtitle">
                Access referrals, career advice, and resume support.
              </p>
            </div>
            <form className="konect-form" onSubmit={handleSignup}>
              <div className="konect-form-row">
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">First Name</legend>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className="konect-input"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">Last Name</legend>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className="konect-input"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
              </div>
              <div className="konect-form-row">
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">Email</legend>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="konect-input"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">Mobile</legend>
                    <input
                      type="tel"
                      name="mobile"
                      placeholder="Mobile"
                      className="konect-input"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
              </div>
              <div className="konect-form-row">
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">Role</legend>
                    <select
                      name="userRole"
                      className="konect-select"
                      value={formData.userRole}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Role</option>
                      <option value="jobSeeker">Job Seeker</option>
                      <option value="referrer">Referrer</option>
                    </select>
                  </fieldset>
                </div>
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">
                      Date of Birth
                    </legend>
                    <input
                      type="date"
                      name="dateOfBirth"
                      className="konect-input"
                      value={formData.dateOfBirth}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
              </div>
              <div className="signin-button-container">
                <button type="submit" className="konect-submit">
                  Continue
                </button>
              </div>
            </form>
            {/* <div className="konect-divider">or</div> */}
            {/* <div className="konect-social-buttons">
              <button className="konect-social-button konect-google">
                <img
                  src={googleIcon}
                  alt="Google"
                  className="sign-modal-socialmedia-icon"
                />
                Google
              </button>
              <button className="konect-social-button konect-apple">
                <img
                  src={appleIcon}
                  alt="Apple"
                  className="sign-modal-socialmedia-icon"
                />
                Apple
              </button>
              <button className="konect-social-button konect-linkedin">
                <img
                  src={linkedinIcon}
                  alt="LinkedIn"
                  className="sign-modal-socialmedia-icon"
                />
                LinkedIn
              </button>
            </div> */}
            <p className="konect-signin-prompt">
              Already have an account?{" "}
              <a className="konect-signin-link" onClick={hanldeSignModalOpen}>
                Sign in
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {otpVarification ? (
        // OTP verification form
        <div className="konect-signin-container">
          <div className="signin-modal-img-container">
            <img
              src={signInOtpImg}
              className="signin-modal-img"
              alt="Sign In"
            />
          </div>
          <div>
            <div className="signin-heading-container">
              <h2 className="konect-modal-title">OTP Verification </h2>
              <div className="konect-modal-subtitle modal-subtitle-margin">
                Enter the one-time password we've sent to your
              </div>
              <div className="otp-v-email-section">
                <span className="otp-v-email">{email}</span>
                <button
                  className="otp-v-resend-btn"
                  onClick={handleBackToSignUp}
                >
                  Edit
                </button>
              </div>
            </div>
            <form className="konect-form">
              <div className="konect-form-row">
                <div className="otp-v-input-group">
                  {Array.isArray(otp) &&
                    otp?.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        // onKeyDown={(e) => handleKeyDown(index, e)}
                        className={`otp-v-input ${
                          index === 0 ? "otp-v-input-active" : ""
                        }`}
                        maxLength={1}
                        required
                      />
                    ))}
                </div>
              </div>

              {errorMessage && (
                <div
                  className="error-message"
                  style={{ color: "red", marginTop: "10px" }}
                >
                  {errorMessage}
                </div>
              )}
              <div className="signin-button-container">
                {timer > 0 && (
                  <p
                    className="timer-text"
                    style={{
                      color: "gray",
                      marginBottom: "6px",
                      fontSize: "12px",
                    }}
                  >
                    You can resend the code in{" "}
                    <span className="timer-text-time-color">
                      {timer} seconds
                    </span>
                  </p>
                )}
                <p className="otp-v-resend-text">
                  Don't Receive OTP?{" "}
                  <button
                    className="otp-v-resend-btn"
                    onClick={handleResendCode}
                    disabled={timer > 0}
                  >
                    Resend Code
                  </button>
                </p>

                <button
                  type="submit"
                  className="konect-submit"
                  onClick={handleOtpVerify}
                  disabled={otp?.some((digit) => digit.trim() === "")}
                >
                  Verify OTP
                </button>
                <p className="otp-v-resend-text">
                  Back to{" "}
                  <button
                    className="otp-v-resend-btn"
                    onClick={handleBackToSignUp}
                  >
                    Sign Up{" "}
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* create password */}
      {isCreatePassword ? (
        <div className="konect-signin-container create-password-modal">
          <div className="signin-modal-img-container">
            <img
              src={signInOtpImg}
              className="signin-modal-img"
              alt="Sign In"
            />
          </div>
          <div>
            <div className="signin-heading-container">
              <h2 className="konect-modal-title">Create Your Password </h2>
              <p className="konect-modal-subtitle">
                Access referrals, career advice, and resume support.
              </p>
            </div>
            <form
              className="konect-form-password-container"
              onSubmit={handleCreatePassword}
            >
              <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                <legend className="signin-input-label">Password</legend>
                <input
                  type="password"
                  placeholder="Password"
                  className="konect-input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </fieldset>
              <div className="konect-signup-input-validation">
                Password must contain at least 12 characters.
              </div>

              <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                <legend className="signin-input-label">Confirm Password</legend>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="konect-input"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </fieldset>
              <div className="konect-signup-input-validation">
                Password must match the confirmation.
              </div>

              {errorMessage && (
                <div
                  className="error-message"
                  style={{ color: "red", marginTop: "10px" }}
                >
                  {errorMessage}
                </div>
              )}

              <div className="signin-button-container">
                <button type="submit" className="konect-submit">
                  Continue
                </button>
              </div>
            </form>
            <div className="konect-divider">or</div>
            <p className="konect-signin-prompt">
              Back to Sign Up {" "}
              <a className="konect-signin-link" onClick={hanldeSignModalOpen}>
                Sign in
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* Login modal */}
      {isLoginModal ? (
        <div className="konect-signin-container">
          <div className="signin-modal-img-container">
            <img
              src={signInOtpImg}
              className="signin-modal-img"
              alt="Sign In"
            />
          </div>
          <div>
            <div className="signin-heading-container">
              <h2 className="konect-modal-title">Welcome Back!</h2>
              <p className="konect-modal-subtitle">
                Access referrals, career advice, and resume support.
              </p>
            </div>
            <form
              className="konect-form-password-container"
              onSubmit={handleSignin}
            >
              <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                <legend className="signin-input-label">Mail</legend>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="konect-input"
                  required
                />
              </fieldset>

              <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                <legend className="signin-input-label">Password </legend>
                <input
                  type="password"
                  placeholder="password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                  className="konect-input"
                  required
                />
              </fieldset>
              <div
                className="konect-signup-input-validation forgot-password"
                onClick={handleOpenForgotPassword}
              >
                Forget Password?
              </div>

              <div className="signin-button-container">
                <button type="submit" className="konect-submit">
                  Continue
                </button>
              </div>
            </form>
            {/* <div className="konect-divider">or</div>
            <div className="konect-social-buttons">
              <button className="konect-social-button konect-google">
                <img
                  src={googleIcon}
                  alt="Google"
                  className="sign-modal-socialmedia-icon"
                />
                Google
              </button>
              <button className="konect-social-button konect-apple">
                <img
                  src={appleIcon}
                  alt="Apple"
                  className="sign-modal-socialmedia-icon"
                />
                Apple
              </button>
              <button className="konect-social-button konect-linkedin">
                <img
                  src={linkedinIcon}
                  alt="LinkedIn"
                  className="sign-modal-socialmedia-icon"
                />
                LinkedIn
              </button>
            </div> */}
            <p className="konect-signin-prompt">
              Don’t have an account? {" "}
              <a
                href="#"
                className="konect-signin-link"
                onClick={hanldeSignUpModalOpen}
              >
                Create Account
              </a>
            </p>
            <p className="konect-login-modal">
              {" "}
              By joining, you agree to the Konect Terms of Service and to
              occasionally receive emails from us. Please read our Privacy
              Policy to learn how we use your personal data.
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* forgotpassword_Modal */}
      {isForgotPassword ? (
        <div className="konect-signin-container">
          <div className="signin-modal-img-container">
            <img
              src={signInOtpImg}
              className="signin-modal-img"
              alt="Sign In"
            />
          </div>
          <div className="forgot-password-container">
            <div className="signin-heading-container">
              <h2 className="konect-modal-title">Forgot Password!</h2>
            </div>
            {!isForgotPasswordOTP && !isForgotPasswordConfirm ? (
              <form
                className="konect-form-password-container"
                onSubmit={handleForgotMailSubmit}
              >
                <fieldset className="signin-input-fieldset forgotpassword-fieldset">
                  <legend className="signin-input-label">Mail</legend>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="konect-input"
                    required
                  />
                </fieldset>
                <div className="signin-button-container">
                  <button type="submit" className="konect-submit">
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <div></div>
            )}

            {isForgotPasswordOTP ? (
              <form className="konect-form">
                <div className="konect-form-row">
                  <div className="otp-v-input-group">
                    {Array.isArray(otp) &&
                      otp?.map((digit, index) => (
                        <input
                          key={index}
                          ref={(el) => (inputRefs.current[index] = el)}
                          type="text"
                          value={digit}
                          onChange={(e) => handleChange(index, e.target.value)}
                          // onKeyDown={(e) => handleKeyDown(index, e)}
                          className={`otp-v-input ${
                            index === 0 ? "otp-v-input-active" : ""
                          }`}
                          maxLength={1}
                          required
                        />
                      ))}
                  </div>
                </div>

                {errorMessage && (
                  <div
                    className="error-message"
                    style={{ color: "red", marginTop: "10px" }}
                  >
                    {errorMessage}
                  </div>
                )}
                <div className="signin-button-container">
                  {timer > 0 && (
                    <p
                      className="timer-text"
                      style={{
                        color: "gray",
                        marginBottom: "6px",
                        fontSize: "12px",
                      }}
                    >
                      You can resend the code in{" "}
                      <span className="timer-text-time-color">
                        {timer} seconds
                      </span>
                    </p>
                  )}
                  <p className="otp-v-resend-text">
                    Don't Receive OTP?{" "}
                    <button
                      className="otp-v-resend-btn"
                      onClick={handleResendCode}
                      disabled={timer > 0}
                    >
                      Resend Code
                    </button>
                  </p>

                  <button
                    type="submit"
                    className="konect-submit"
                    onClick={handleForgotOtpVerify}
                    disabled={
                      Array.isArray(otp) &&
                      otp?.some((digit) => digit.trim() === "")
                    }
                  >
                    Verify OTP
                  </button>
                </div>
              </form>
            ) : (
              <div></div>
            )}

            {isForgotPasswordConfirm ? (
              <form
                className="konect-form-password-container"
                onSubmit={handleForgotCreatePassword}
              >
                <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                  <legend className="signin-input-label">Password</legend>
                  <input
                    type="password"
                    placeholder="Password"
                    className="konect-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </fieldset>
                <div className="konect-signup-input-validation">
                  Password must contain at least 12 characters.
                </div>

                <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                  <legend className="signin-input-label">
                    Confirm Password
                  </legend>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className="konect-input"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </fieldset>
                <div className="konect-signup-input-validation">
                  Password must match the confirmation.
                </div>

                {errorMessage && (
                  <div
                    className="error-message"
                    style={{ color: "red", marginTop: "10px" }}
                  >
                    {errorMessage}
                  </div>
                )}

                <div className="signin-button-container">
                  <button type="submit" className="konect-submit">
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </Modal>
  );
};

export default SignInModal;
