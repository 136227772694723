import { showToastMessage } from "../../redux/actions/toastActions";
import { startLoading, stopLoading } from "../../redux/actions/spinnerActions";
import {
  createJobReferralRequest,
  createResumeReviewRequest,
  fetchDashboardSuccess,
  fetchDashboarsdFailure,
  getJobReferralById,
  getJobReferrals,
  getResumeReview,
  getResumeReviewById,
  updateReferrerFeedback,
  updateResumeReviewFeedback,
  accountVerificationRequest,
  fetchReferrerInfoSuccess,
  fetchReferrerInfoFailure,
  accountVerificationSuccess,
  accountVerificationFailure,
  fetchReferralDashboard,
  getReferralResumeReviewById,
} from "../../redux/actions/referrerAction";
import { apiRequest } from "../../utils/apiRequest";
import axios from "axios";

// Helper function to dispatch error messages
const handleApiError = (dispatch, error, errorMessage) => {
  console.error(errorMessage, error);
  dispatch(showToastMessage(errorMessage, "error"));
};

export const createAccountVerification = (formData) => {
  return async (dispatch) => {
    dispatch(startLoading());

    try {
      // Debug: Log formData contents

      const response = await apiRequest(
        "/api/referrer/send-verification-request",

        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          `Error: ${response.statusText}. Server Response: ${JSON.stringify(
            errorResponse
          )}`
        );
      }

      const data = await response.json();
      console.log(data);

      dispatch(accountVerificationSuccess(data));
      dispatch(stopLoading());
      return { status: "success", data };
    } catch (error) {
      console.error("API Error:", error);
      dispatch(accountVerificationFailure(error.message));
      return { status: "error", error: error.message };
    }
  };
};

// Upload Verification Documents
// export const uploadVerificationDocuments = (formData) => {
//   return async (dispatch) => {
//     dispatch(startLoading());
//     try {
//       const response = await apiRequest(`/api/referrer/send-verification-request`, {
//         method: "POST",
//         body: formData,
//       }, true, dispatch);

//       dispatch(showToastMessage("Verification documents submitted successfully!", "success"));
//       return response;
//     } catch (error) {
//       dispatch(showToastMessage("Failed to submit verification documents.", "error"));
//       console.error("Error uploading documents:", error);
//     } finally {
//       dispatch(stopLoading());
//     }
//   };
// };

// Fetch Referrer Information
export const fetchReferrerInformation = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await apiRequest(
        `/api/user/profile`,
        {},
        false,
        dispatch
      );
      dispatch(fetchReferrerInfoSuccess(response.data));
    } catch (error) {
      dispatch(fetchReferrerInfoFailure(error));
      handleApiError(dispatch, error, "Failed to fetch Referrer Information.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Fetch job seeker list
export const fetchDashboard = (queryParams = {}) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await apiRequest(
        `/api/job-referral/job-seeker/dashboard`,
        {},
        false,
        dispatch
      );
      //pass response not response.data
      console.log(response);

      dispatch(fetchDashboardSuccess(response.data));
      // dispatch(showToastMessage("Referrer fetched successfully!", "success"));
    } catch (error) {
      dispatch(fetchDashboarsdFailure(error));
      handleApiError(dispatch, error, "Failed to fetch Referrer.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getJobReferral = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    console.log("Fetching job referral with ID:", id);
    try {
      const response = await apiRequest(
        `/api/job-referral/job-seeker/${id}`,
        {},
        false,
        dispatch
      );
      console.log("API Response:", response.data);
      dispatch(getJobReferralById(response.data));
      // dispatch(showToastMessage("Job referral retrieved successfully!", "success"));
    } catch (error) {
      console.error("API Error:", error);
      handleApiError(dispatch, error, "Failed to retrieve job referral.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Update job seeker by ID
export const updateFeedback = (id, referrerFeedback) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const updatedReferrer = await apiRequest(
        `/api/job-referral/job-seeker/${id}/feedback`,
        {
          method: "PUT",
          body: JSON.stringify(referrerFeedback),
        },
        true,
        dispatch
      );
      dispatch(updateReferrerFeedback(updatedReferrer));
      dispatch(
        showToastMessage("Jobseeker feedback updated successfully!", "success")
      );
    } catch (error) {
      handleApiError(dispatch, error, "Failed to update jobseeker feedback.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get job referrals
export const fetchJobReferrals = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const jobReferralData = await apiRequest(
        `/api/job-referral/job-seeker`,
        {},
        false,
        dispatch
      );
      console.log("Job Referral Data :", jobReferralData);

      dispatch(getJobReferrals(jobReferralData.data || []));
      // dispatch(showToastMessage("Job Referrals retrieved successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to retrieve job referrals.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Add new job referral
export const addJobReferralRequest = (jobReferralData) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(jobReferralData).forEach((key) => {
        formData.append(key, jobReferralData[key]);
      });

      const response = await apiRequest(
        "/api/job-referral/job-seeker",
        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch
      );

      console.log(response.data);
      dispatch(createJobReferralRequest(response.data));
      dispatch(showToastMessage("Job Referral added successfully!", "success"));
      return { status: "success", data: response };
    } catch (error) {
      handleApiError(dispatch, error, "Failed to add job referral.");
      return { status: "error", error };
    } finally {
      dispatch(stopLoading());
    }
  };
};
// Add new job referral
export const createAccountVerificationRequest = (jobReferralData) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(jobReferralData).forEach((key) => {
        formData.append(key, jobReferralData[key]);
      });

      const response = await apiRequest(
        "/api/referrer/send-verification-request",
        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch
      );

      console.log(response.data);
      dispatch(accountVerificationRequest(response.data));
      dispatch(showToastMessage("Job Referral added successfully!", "success"));
      return { status: "success", data: response };
    } catch (error) {
      handleApiError(dispatch, error, "Failed to add job referral.");
      return { status: "error", error };
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get job referrals
export const fetchResumeReview = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resumeReviewData = await apiRequest(
        `/api/resume-review/job-seeker`,
        {},
        false,
        dispatch
      );
      console.log("Resume Review Data :", resumeReviewData);

      dispatch(getResumeReview(resumeReviewData.data || []));
      // dispatch(showToastMessage("Resume Review data retrieved successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to retrieve resume review");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Add new resume request
export const addResumeReviewRequest = (jobReferralData) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(jobReferralData).forEach((key) => {
        formData.append(key, jobReferralData[key]);
      });

      const response = await apiRequest(
        "/api/resume-review/job-seeker",
        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch
      );

      console.log(response.data);
      dispatch(createResumeReviewRequest(response.data));
      dispatch(
        showToastMessage("Resume Review added successfully!", "success")
      );
      return { status: "success", data: response };
    } catch (error) {
      handleApiError(dispatch, error, "Failed to add resume review");
      return { status: "error", error };
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getResumeReviewData = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    console.log("Fetching resume  review with ID:", id);
    try {
      const response = await apiRequest(
        `/api/resume-review/job-seeker/${id}`,
        {},
        false,
        dispatch
      );
      console.log("API Response:", response.data);
      dispatch(getResumeReviewById(response.data));
      dispatch(
        showToastMessage("Resume Review retrieved successfully!", "success")
      );
    } catch (error) {
      console.error("API Error:", error);
      handleApiError(dispatch, error, "Failed to retrieve resume review.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Update resume review by ID
export const updateResumeReview = (id, resumeReviewFeedback) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const updatedResumeReview = await apiRequest(
        `/api/resume-review/job-seeker/${id}/feedback`,
        {
          method: "PUT",
          body: JSON.stringify(resumeReviewFeedback),
        },
        true,
        dispatch
      );
      dispatch(updateResumeReviewFeedback(updatedResumeReview));
      dispatch(
        showToastMessage(
          "Resume Review feedback updated successfully!",
          "success"
        )
      );
    } catch (error) {
      handleApiError(
        dispatch,
        error,
        "Failed to update resume review feedback."
      );
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Fetch referral dashboard
export const fetchReferralDashboardAyns = () => {
  return async (dispatch) => {
    console.log("service start to call");

    dispatch(startLoading());
    try {
      const response = await apiRequest(
        `/api/job-referral/referrer`,
        {},
        false,
        dispatch
      );
      if (response.statusCode === 200) {
        dispatch(fetchReferralDashboard(response));
        // dispatch(showToastMessage("Resume referral fetched successfully!", "success"));
      }
    } catch (error) {
      console.error("Failed to fetch resume referral", error);
      handleApiError(dispatch, error, "Failed to fetch Resume referral.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getReferralResumeReviewByIdAync = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    console.log("Fetching resume  review with ID:", id);
    try {
      const response = await apiRequest(
        `/api/job-referral/referrer/${id}`,
        {},
        false,
        dispatch
      );
      console.log("API Response:", response.data);
      dispatch(getReferralResumeReviewById(response.data));
      dispatch(
        showToastMessage("Resume Review retrieved successfully!", "success")
      );
    } catch (error) {
      console.error("API Error:", error);
      handleApiError(dispatch, error, "Failed to retrieve resume review.");
    } finally {
      dispatch(stopLoading());
    }
  };
};
