import React, { useState, useEffect } from "react";
import FileUpload from "../../../../components/FileUpload/FileUpload";
import "./JobReferralsNewRequest.css";
import questionCircle from "../../../../assets/images/UserLayout/Icons/question-circle.svg";
import creditCard from "../../../../assets/images/UserLayout/Icons/credit_card.svg";
import closeIcon from "../../../../assets/images/UserLayout/Icons/close.svg";
import {
  addJobReferralRequest,
  fetchIndustries,
  fetchCompanies,
} from "../../../../services/jobSeeker/jobSeekerService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormLoader from "../../../../components/Loader/FormLoader/FormLoader";

const JobReferralsNewRequest = () => {
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  // const userData = JSON.parse(localStorage.getItem("user"));
  const userData = JSON.parse(localStorage.getItem("user")) || {};
  const industries = useSelector((state) => state.jobSeeker.industries || []);
  const companies = useSelector((state) => state.jobSeeker.companies || []);
  const [industry, setIndustry] = useState([]);
  const [company, setCompany] = useState([]);

  // userData.personalInfo.firstname + userData.user.personalInfo.lastname
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name:
      userData.user.personalInfo.firstName +
      "\u00A0" +
      userData.user.personalInfo.lastName,
    email: userData.user.email,
    mobile: userData.user.mobile,
    industry: "",
    desiredPosition: "",
    desiredCompany: "",
    currentJob: "",
    paymentMethod: "CreditCard",
    paidDate: new Date().toISOString(),
    paidAmount: "500",
  });

  // useEffect(() => {

  //   dispatch(fetchIndustries());
  //   dispatch(fetchCompanies());
  // }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchIndustries());
        await dispatch(fetchCompanies());
      } catch (error) {
        console.error("Error fetching industries or companies:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (industries && industries.length > 0) {
      setIndustry(industries);
    }
  }, [industries]);

  useEffect(() => {
    if (companies && companies.length > 0) {
      setCompany(companies);
    }
  }, [companies]);

  //   console.log("Industries:", industries);
  // console.log("Companies:", companies);

  // const state = useSelector((state) => state);
  // console.log(state);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };


  const handleInputChange = (e, fieldName = null) => {
    const { name, value } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [fieldName || name]: value,
    }));
  };
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const submissionData = {
  //     ...formData,
  //     resume: resume || null,
  //     coverLetter: coverLetter || null,
  //   };
  //   console.log(submissionData);

  //   const response = await dispatch(addJobReferralRequest(submissionData));
  //   console.log(response.data.statusCode);

  //   if (response?.data?.statusCode === 200 && response?.data?.data?._id) {
  //     navigate(`/user_dashboard/job_referrals/${response?.data?.data?._id}`);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
      const submissionData = {
        ...formData,
        resume: resume || null,
        coverLetter: coverLetter || null,
      };
      console.log(submissionData);

      const response = await dispatch(addJobReferralRequest(submissionData));
      console.log(response?.data?.statusCode);

      if (response?.data?.statusCode === 200 && response?.data?.data?._id) {
        navigate(`/user_dashboard/job_referrals/${response?.data?.data?._id}`);
      } 
   
  };

  const handleResumeUpload = (file) => {
    console.log("Resume Uploaded:", file);
    setResume(file);
  };

  const handleCoverLetterUpload = (file) => {
    console.log("Cover Letter Uploaded:", file);
    setCoverLetter(file);
  };

  const handleCancel = () => {
    navigate("/user_dashboard/job_referrals");
  };

  return (
    <div className="user-job-referral-form-request-container">
      <FormLoader/>
      <h4>New Job Referral Request</h4>
      <p>Please fill in the information below to start the referral process.</p>

      <form className="user-job-referral-form-request" onSubmit={handleSubmit}>
        <div>
          <h5>User Information Form</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-input"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  E-Mail Address<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  className="form-input"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email address"
                  required
                />
              </div>
            </div>
            <div className="user-job-referral-form-input-container">
              <label className="form-label">
                Your Phone Number<span className="required">*</span>
              </label>
              <input
                type="text"
                name="mobile"
                className="form-input"
                value={formData.mobile}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
                required
              />
            </div>
          </div>
        </div>

        <div>
          <h5>Industry Selection</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Select Your Industry<span className="required">*</span>
                </label>
                <select
                  name="industry"
                  class="form-input"
                  value={formData.industry}
                  // onChange={handleInputChange}
                  onChange={(e) => handleInputChange(e, "industry")}
                  required
                >
                  <option value="" disabled selected>
                    Choose the industry that matches your desired position
                  </option>
                  {industry.length > 0 ? (
                    industry.map((industry, index) => (
                      <option key={index} value={industry}>
                        {industry}
                      </option>
                    ))
                  ) : (
                    <option>Loading industries...</option>
                  )}
                  {/* {industry.length > 0 ? (
                    industry.map((industryItem) => (
                      <option key={industryItem._id} value={industryItem._id}>
                        {industryItem.name}
                      </option>
                    ))
                  ) : (
                    <option>Loading industries...</option>
                  )} */}
                </select>
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Desired Position<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="desiredPosition"
                  className="form-input"
                  value={formData.desiredPosition}
                  onChange={handleInputChange}
                  placeholder="Enter the URL of your desired position"
                  required
                />
              </div>
            </div>

            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Select Desired Company<span className="required">*</span>
                </label>
                <select
                  name="desiredCompany"
                  class="form-input"
                  value={formData.desiredCompany}
                  // onChange={handleInputChange}
                  onChange={(e) => handleInputChange(e, "desiredCompany")}
                  required
                >
                  <option value="" disabled selected>
                    Choose a desired company
                  </option>

                  {/* {company.length > 0 ? (
                    company.map((company) => (
                      <option key={company._id} value={company.name}>
                        {company.name}
                      </option>
                    ))
                  ) : (
                    <option>Loading companies...</option>
                  )} */}
                  {company.length > 0 ? (
                    company.map((companyItem) => (
                      <option key={companyItem._id} value={companyItem._id}>
                        {companyItem.name}
                      </option>
                    ))
                  ) : (
                    <option>Loading companies...</option>
                  )}
                </select>
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Current Job<span className="required">*</span>
                </label>

                <input
                  type="text"
                  name="currentJob"
                  className="form-input"
                  value={formData.currentJob}
                  onChange={handleInputChange}
                  placeholder="Enter the URL of your current job"
                  required
                />
                
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="upload-section">
            <h5>Upload Section</h5>
            {/* <FileUpload
            label="Upload Your Resume"
            onFileSelect={handleResumeUpload}
          />
          <FileUpload
            label="Upload Your Cover Letter (Optional)"
            onFileSelect={handleCoverLetterUpload}
          /> */}
            <FileUpload
              fileHeading="Upload Your Resume"
              uploadDes="Drag and Drop or Choose your resume to upload"
              onFileSelect={handleResumeUpload}
              isRequired={true}
            />
          </div>

          <div className="upload-section">
            <FileUpload
              fileHeading="Upload Your Cover Letter "
              uploadDes="Drag and Drop or Choose your cover letter to upload"
              onFileSelect={handleCoverLetterUpload}
              isRequired={true}
            />
            <p className="upload-des">
              <img src={questionCircle} className="question-circle-icon" />
              Don’t have a Resume or Cover Letter? Click Here
            </p>
          </div>

          {/* <div className="upload-section">
            <p className="resume-file-heading">Description </p>
            <textarea
              className="contact-form-textarea user-request-form"
              placeholder="Enter your description here..."
              rows="10"
            />
          </div> */}
        </div>
        <div className="user-request-form-actions">
          <button
            className="button-common user-dashboard-table-button pay-cancel-button"
            onClick={handleCancel}
          >
            <img src={closeIcon} class="user-dashboard-table-button-icon" />
            Cancel
          </button>
          <button className="button-common user-dashboard-table-button">
            <img src={creditCard} class="user-dashboard-table-button-icon" />
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default JobReferralsNewRequest;
