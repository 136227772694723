import React, { useEffect, useState } from "react";
import "./ServiceManagemantTable.css";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../../components/TitlePath/TitlePath";
import DashboardTable from "../../../../components/DashboardTable/DashboardTable";
import DashboardHeading from "../../../../components/DashboardHeading/DashboardHeading";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { useLocation } from "react-router-dom";
import { fetchAdminResumeReviewAsyn } from "../../../../services/jobSeeker/jobSeekerService";
import { useDispatch, useSelector } from "react-redux";

const ResumeReviewTable = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("All");
  const location = useLocation();

  const dispatch = useDispatch();

  const resumeReview = useSelector(
    (state) => state.jobSeeker.adminResumeReview
  );

  console.log("resume Review : ", resumeReview);

  useEffect(() => {
    dispatch(fetchAdminResumeReviewAsyn());
  }, [dispatch]);

  // Calculate counts for each tab
  const allCount = resumeReview.length;
  const approvedCount = resumeReview.filter(
    (review) => review.currentStatus === "completed"
  ).length;
  const pendingCount = resumeReview.filter(
    (review) => review.currentStatus === "pending"
  ).length;

  // Filter admins based on active tab
  const filteredData =
    activeTab === "All"
      ? resumeReview
      : resumeReview.filter(
          (review) => review.currentStatus === activeTab.toLowerCase()
        );

  const tabs = [
    { name: "All", count: allCount },
    { name: "Completed", count: approvedCount },
    { name: "Pending", count: pendingCount },
  ];

  const columns = [
    { key: "id", label: "ID", isClickable: true },
    { key: "name", label: "User Name", isClickable: true },
    { key: "currentJob", label: "Current Job", isClickable: true },
    { key: "submissionDate", label: "Submission Date", isClickable: true },
    {
      key: "currentStatus",
      label: "Status",
      type: "status",
      sortable: true,
      isClickable: true,
    },
  ];

  const handleViewClick = (id) => {
    console.log("id", id);
    navigate(`/admin_dashboard/resume_review/${id}`);
  };

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };
  const titlePath = {
    exactPath: `discussion_forum`,
    paths: [
      { label: "Dashboard > ", onClick: handleBack },
      // { label: "Dashboard > ", onClick: handleBack },
    ],
  };
  const headingDes = {
    heading: "Service Management",
    des: "Streamline and oversee all services to ensure seamless operations and user satisfaction.",
  };
  const subNav = [
    { navHeading: "Job Referrals" },
    { navHeading: "Career Advice" },
    { navHeading: "Resume Review" },
  ];

  const [navClick, setNavClick] = useState("Job Referrals");
  const [heading, setHeading] = useState("Job Referrals");
  const [activeHeading, setActiveHeading] = useState("Job Referrals");

  // Update your useEffect to track the current path
  useEffect(() => {
    const currentPath = location.pathname;

    // Match pathnames to update the activeHeading
    if (currentPath.includes("service_management")) {
      setActiveHeading("Job Referrals");
    } else if (currentPath.includes("career_advice_table")) {
      setActiveHeading("Career Advice");
    } else if (currentPath.includes("resume_review")) {
      setActiveHeading("Resume Review");
    } else {
      setActiveHeading("Job Referrals"); // Default or fallback
    }
  }, [location.pathname]);

  const handleClick = (heading) => {
    setActiveHeading(heading);
    setHeading(heading);

    if (heading === "Job Referrals") {
      navigate("/admin_dashboard/service_management");
    } else if (heading === "Career Advice") {
      navigate("/admin_dashboard/career_advice_table");
    } else if (heading === "Resume Review") {
      navigate("/admin_dashboard/resume_review");
    }
  };

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath
          exactPath={titlePath.exactPath}
          paths={titlePath.paths}
          showNotification={true}
        />
        <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      </div>
      <div className="setting-page-container">
        <div className="dashboard-subnav-button-container">
          {subNav.map((data) => (
            <div
              key={data.navHeading}
              onClick={() => handleClick(data.navHeading)}
              className={`subnav-button-item ${
                activeHeading === data.navHeading ? "active" : ""
              }`}
            >
              <div>{data.navHeading}</div>
            </div>
          ))}
        </div>

        <div className="sub-setting-page-container blog-post-table-container">
          <DashboardTable
            tableData={filteredData}
            tableHeading={columns}
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            handleViewClick={handleViewClick}
          />
          {/* <CustomTable
            columns={columns}
            data={data}
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ResumeReviewTable;
