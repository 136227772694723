import React from "react";
import Questions from "../containers/Home/Questions";

const FrequentlyAskedQuestions = () => {
  return (
    <>
      <div className="main-header-heading-condatiner">
        <h1>Frequently Asked Questions</h1>
        <p>
          Find quick answers to common questions about Konect and how our
          services can help boost your career
        </p>
      </div>
      <div className="main-home-container">
        <Questions />
      </div>
    </>
  );
};

export default FrequentlyAskedQuestions;
