import React from "react";
import "./Pagination.css";

const Pagination = ({
  currentPage,
  totalPages,
  paginate,
  singleArrowRight,
  doubleArrowRight,
}) => {
  // Helper function to create the page numbers list
  const createPageNumbers = () => {
    const pageNumbers = [];

    // Always show the first 4 pages
    for (let i = 1; i <= 4; i++) {
      pageNumbers.push(i);
    }

    // Show the current page + the next 2 pages
    if (currentPage > 4 && currentPage < totalPages - 3) {
      pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
    }

    // Always show the last 4 pages
    if (totalPages > 4) {
      pageNumbers.push(".....");
      for (let i = totalPages - 3; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const pageNumbers = createPageNumbers();

  return (
    <div className="pagination">
      {/* Previous Buttons */}
      <div className="previous-next-button-icon-container">
        <button
          className="pagination-arrow-button"
          onClick={() => paginate(1)}
          disabled={currentPage === 1}
        >
          <img src={doubleArrowRight} className="previous-next-button-img" />
        </button>
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-arrow-button"
        >
          <img src={singleArrowRight} className="previous-next-button-img" />
        </button>
      </div>

      {/* Page Numbers */}
      <div>
        {pageNumbers.map((number, index) => (
          <button
            key={index}
            onClick={() => number !== "....." && paginate(number)}
            className={number === currentPage ? "active" : ""}
            style={
              number === "....."
                ? {
                    backgroundColor: "transparent",
                    cursor: "default",
                    fontSize: "18px",
                    color: "#CACACB",
                  }
                : {}
            }
            // disabled={number === '...'}
          >
            {number}
          </button>
        ))}
      </div>

      {/* Next Buttons */}
      <div className="previous-next-button-icon-container">
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-arrow-button"
        >
          <img
            src={singleArrowRight}
            className="previous-next-button-img"
            style={{ transform: "scaleX(-1)" }}
          />
        </button>
        <button
          className="pagination-arrow-button"
          onClick={() => paginate(totalPages)}
          disabled={currentPage === totalPages}
        >
          <img
            src={doubleArrowRight}
            className="previous-next-button-img"
            style={{ transform: "scaleX(-1)" }}
          />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
