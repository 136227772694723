// Authentication Action Types
export const ACCOUNT_VERIFICATION_SUCCESS = "ACCOUNT_VERIFICATION_SUCCESS";
export const ACCOUNT_VERIFICATION_FAILURE = "ACCOUNT_VERIFICATION_FAILURE";
export const FETCH_REFERRER_INFO_SUCCESS = "FETCH_REFERRER_INFO_SUCCESS";
export const FETCH_REFERRER_INFO_FAILURE = "FETCH_REFERRER_INFO_FAILURE";
export const CREATE_REFERRER_SUCCESS = "CREATE_REFERRER_SUCCESS";
export const CREATE_REFERRER_FAILURE = "CREATE_REFERRER_FAILURE";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";
export const GET_JOB_REFERRAL_BY_ID = "GET_JOB_REFERRAL_BY_ID";
export const GET_RESUME_REVIEW_BY_ID = "GET_RESUME_REVIEW_BY_ID";
export const GET_JOB_REFERRALS = "GET_JOB_REFERRALS";
export const FETCH_REFERRAL_DASHBOARD = "FETCH_REFERRAL_DASHBOARD";
export const GET_RESUME_REVIEW = "GET_RESUME_REVIEW";
export const GET_REFERRAL_RESUME_REVIEW_BY_ID =
  "GET_REFERRAL_RESUME_REVIEW_BY_ID";
export const UPDATE_JOB_SEEKER_FEEDBACK = "UPDATE_JOB_SEEKER_FEEDBACK";
export const UPDATE_RESUME_REVIWE_FEEDBACK = "UPDATE_RESUME_REVIWE_FEEDBACK";
export const CREATE_JOB_REFERRAL_REQUEST = "CREATE_JOB_REFERRAL_REQUEST";
export const ACCOUNT_VERIFICATION_REQUEST = "ACCOUNT_VERIFICATION_REQUEST";
export const CREATE_RESUME_REVIEW_REQUEST = "CREATE_JOB_REFERRAL_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
export const CREATE_PASSWORD_SUCCESS = "CREATE_PASSWORD_SUCCESS";
export const CREATE_PASSWORD_FAILURE = "CREATE_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_SEND_OTP_SUCCESS =
  "FORGOT_PASSWORD_SEND_OTP_SUCCESS";
export const FORGOT_PASSWORD_SEND_OTP_FAILURE =
  "FORGOT_PASSWORD_SEND_OTP_FAILURE";
export const FORGOT_PASSWORD_VERIFY_OTP_SUCCESS =
  "FORGOT_PASSWORD_VERIFY_OTP_SUCCESS";
export const FORGOT_PASSWORD_VERIFY_OTP_FAILURE =
  "FORGOT_PASSWORD_VERIFY_OTP_FAILURE";
export const FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS =
  "FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE =
  "FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE";

export const accountVerificationSuccess = (data) => ({
  type: ACCOUNT_VERIFICATION_SUCCESS,
  payload: data,
});
export const accountVerificationFailure = (error) => ({
  type: ACCOUNT_VERIFICATION_FAILURE,
  payload: { error },
});

// Action Creators
export const fetchReferrerInfoSuccess = (data) => ({
  type: FETCH_REFERRER_INFO_SUCCESS,
  payload: data,
});

export const fetchReferrerInfoFailure = (error) => ({
  type: FETCH_REFERRER_INFO_FAILURE,
  payload: error,
});

// Create REFERRER Success
export const createReferrerSuccess = (referrer) => ({
  type: CREATE_REFERRER_SUCCESS,
  payload: referrer,
});

// Create User Failure
export const createReferrerFailure = (error) => ({
  type: CREATE_REFERRER_FAILURE,
  payload: error,
});

// Create Dashboard Success
export const fetchDashboardSuccess = (referrer) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: referrer,
});

// Create Dashboard Failure
export const fetchDashboarsdFailure = (error) => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: error,
});

// Get Job Referral By Id
export const getJobReferralById = (referrer) => ({
  type: GET_JOB_REFERRAL_BY_ID,
  payload: referrer,
});

// Update Job Seeker Feedback
export const updateReferrerFeedback = (referrerFeedback) => ({
  type: UPDATE_JOB_SEEKER_FEEDBACK,
  payload: referrerFeedback,
});

// Get Job Referrals
export const getJobReferrals = (referrers) => ({
  type: GET_JOB_REFERRALS,
  payload: referrers,
});

// Get Referral Resume Review
export const fetchReferralDashboard = (resumeReviews) => ({
  type: FETCH_REFERRAL_DASHBOARD,
  payload: resumeReviews,
});

// Get Resume Review By Id
export const getResumeReviewById = (resumeReview) => ({
  type: GET_RESUME_REVIEW_BY_ID,
  payload: resumeReview,
});
// Get Resume Review By Id
export const getReferralResumeReviewById = (resumeReview) => ({
  type: GET_REFERRAL_RESUME_REVIEW_BY_ID,
  payload: resumeReview,
});

// Create Job Referral Request
export const createJobReferralRequest = (referrer) => ({
  type: CREATE_JOB_REFERRAL_REQUEST,
  payload: referrer,
});
// Create Job Referral Request
export const accountVerificationRequest = (referrer) => ({
  type: CREATE_JOB_REFERRAL_REQUEST,
  payload: referrer,
});

// Create Job Referral Request
export const createResumeReviewRequest = (resumeReview) => ({
  type: CREATE_RESUME_REVIEW_REQUEST,
  payload: resumeReview,
});

// Update Resume Review Feedback
export const updateResumeReviewFeedback = (resumeReviewFeedback) => ({
  type: UPDATE_RESUME_REVIWE_FEEDBACK,
  payload: resumeReviewFeedback,
});

// Get Resume Review
export const getResumeReview = (resumeReview) => ({
  type: GET_RESUME_REVIEW,
  payload: resumeReview,
});
// Login Success
export const loginSuccess = (referrer) => ({
  type: LOGIN_SUCCESS,
  payload: referrer,
});

// Login Failure
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

// Resend OTP Success
export const resendOTPSuccess = () => ({
  type: RESEND_OTP_SUCCESS,
});

// Resend OTP Failure
export const resendOTPFailure = (error) => ({
  type: RESEND_OTP_FAILURE,
  payload: error,
});

// Verify OTP Success
export const verifyOTPSuccess = () => ({
  type: VERIFY_OTP_SUCCESS,
});

// Verify OTP Failure
export const verifyOTPFailure = (error) => ({
  type: VERIFY_OTP_FAILURE,
  payload: error,
});

// Create Password Success
export const createPasswordSuccess = () => ({
  type: CREATE_PASSWORD_SUCCESS,
});

// Create Password Failure
export const createPasswordFailure = (error) => ({
  type: CREATE_PASSWORD_FAILURE,
  payload: error,
});

// Forgot Password Send OTP Success
export const forgotPasswordSendOTPSuccess = () => ({
  type: FORGOT_PASSWORD_SEND_OTP_SUCCESS,
});

// Forgot Password Send OTP Failure
export const forgotPasswordSendOTPFailure = (error) => ({
  type: FORGOT_PASSWORD_SEND_OTP_FAILURE,
  payload: error,
});

// Forgot Password Verify OTP Success
export const forgotPasswordVerifyOTPSuccess = () => ({
  type: FORGOT_PASSWORD_VERIFY_OTP_SUCCESS,
});

// Forgot Password Verify OTP Failure
export const forgotPasswordVerifyOTPFailure = (error) => ({
  type: FORGOT_PASSWORD_VERIFY_OTP_FAILURE,
  payload: error,
});

// Forgot Password Create Password Success
export const forgotPasswordCreatePasswordSuccess = () => ({
  type: FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS,
});

// Forgot Password Create Password Failure
export const forgotPasswordCreatePasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE,
  payload: error,
});
