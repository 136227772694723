import {
  FETCH_INDUSTRIES_SUCCESS,
  FETCH_INDUSTRIES_FAILURE,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  CREATE_JOBSEEKER_SUCCESS,
  CREATE_JOBSEEKER_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  GET_JOB_REFERRAL_BY_ID,
  GET_RESUME_REVIEW_BY_ID,
  GET_JOB_REFERRALS,
  GET_RESUME_REVIEW,
  UPDATE_JOB_SEEKER_FEEDBACK,
  UPDATE_RESUME_REVIWE_FEEDBACK,
  CREATE_JOB_REFERRAL_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SEND_OTP_SUCCESS,
  FORGOT_PASSWORD_SEND_OTP_FAILURE,
  FORGOT_PASSWORD_VERIFY_OTP_SUCCESS,
  FORGOT_PASSWORD_VERIFY_OTP_FAILURE,
  FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE,
  FETCH_ALL_CHAT_SUCCESS,
  OPEN_CHAT_SUCCESS,
  SEND_MESSAGE_REQUEST,
  FETCH_ADMIN_JOB_REFERRALS,
  FETCH_ADMIN_RESUME_REVIEW,
  GET_ADMIN_JOB_REFERRAL_BY_ID,
  GET_ADMIN_RESUME_REVIWE_BY_ID,
  ADMIN_UPDATE_JOB_REFERRAL_STATUS,
  ADMIN_UPDATE_RESUME_REVIEW,
} from "../actions/jobSeekerAction";

const initialState = {
  jobSeeker: [],
  selectedJobReferral: null,
  resumeReview: [],
  selectedResumeReview: null,
  dashboard: null,
  isAuthenticated: false,
  error: null,
  loading: false,
  otpSent: false,
  passwordReset: false,
  industries: [],
  companies: [],
  chats: [],
  selectedChat: {
    messages: [],
  },
  adminJobReferrals: [],
  selectedAdminJobReferral: null,
  adminResumeReview: [],
  selectedAdminResumeReview: null,
};

// Authentication Reducer
const jobSeekerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INDUSTRIES_SUCCESS:
      return {
        ...state,
        industries: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_INDUSTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADMIN_UPDATE_JOB_REFERRAL_STATUS:
      return {
        ...state,
        selectedAdminJobReferral: state.selectedAdminJobReferral.data.map(
          (jobReferral) =>
            jobReferral._id === action.payload._id
              ? { ...jobReferral, currentStatus: action.payload.currentStatus }
              : jobReferral
        ),
      };
    case ADMIN_UPDATE_RESUME_REVIEW:
      return {
        ...state,
        selectedAdminResumeReview: {
          ...state.selectedAdminResumeReview,
          ...action.payload,
        },
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_ADMIN_JOB_REFERRALS:
      return {
        ...state,
        adminJobReferrals: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_ADMIN_RESUME_REVIEW:
      return {
        ...state,
        adminResumeReview: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_ALL_CHAT_SUCCESS:
      return {
        ...state,
        chats: action.payload,
        loading: false,
        error: null,
      };
    case OPEN_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedChat: action.payload,
        error: null,
      };
    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        loading: false,
        selectedChat: {
          ...state.selectedChat,
          messages: [
            ...(state.selectedChat?.messages || []), // Existing messages
            action.payload,
          ],
        },
        error: null,
      };
    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_JOBSEEKER_SUCCESS:
      return {
        ...state,
        jobSeeker: action.payload,
        isAuthenticated: true,
        error: null,
      };

    case CREATE_JOBSEEKER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
        error: null,
      };

    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case GET_JOB_REFERRAL_BY_ID:
      return {
        ...state,
        selectedJobReferral: action.payload,
      };
    case GET_ADMIN_JOB_REFERRAL_BY_ID:
      return {
        ...state,
        selectedAdminJobReferral: action.payload,
      };
    case GET_ADMIN_RESUME_REVIWE_BY_ID:
      return {
        ...state,
        selectedAdminResumeReview: action.payload,
      };

    case GET_JOB_REFERRALS:
      return {
        ...state,
        jobSeeker: action.payload, // save response
      };
    case GET_RESUME_REVIEW:
      return {
        ...state,
        resumeReview: action.payload,
      };
    case UPDATE_JOB_SEEKER_FEEDBACK:
      return {
        ...state,
        jobSeeker: state.jobSeeker.data.map((jobSeeker) =>
          jobSeeker._id === action.payload._id
            ? { ...jobSeeker, feedback: action.payload.feedback }
            : jobSeeker
        ),
      };
    case GET_RESUME_REVIEW_BY_ID:
      return {
        ...state,
        selectedResumeReview: action.payload,
      };
    case UPDATE_RESUME_REVIWE_FEEDBACK:
      return {
        ...state,
        resumeReview: state.resumeReview.data.map((resumeReview) =>
          resumeReview._id === action.payload._id
            ? { ...resumeReview, feedback: action.payload.feedback }
            : resumeReview
        ),
      };

    // case CREATE_JOB_REFERRAL_REQUEST:
    //   return {
    //     ...state,
    //     jobSeeker: [...state?.jobSeeker, action.payload],
    //   };

    case LOGIN_SUCCESS:
      return {
        ...state,
        jobSeeker: action.payload,
        isAuthenticated: true,
        error: null,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        error: null,
      };

    case RESEND_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpSent: false,
        error: null,
      };

    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true,
        error: null,
      };

    case CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_SEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        error: null,
      };

    case FORGOT_PASSWORD_SEND_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpSent: false,
        error: null,
      };

    case FORGOT_PASSWORD_VERIFY_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true,
        error: null,
      };

    case FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default jobSeekerReducer;
