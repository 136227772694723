import React, { useState } from "react";
import "./DashboardSubNavbar.css";

const DashboardSubNavbar = ({
  setNavClick,
  setHeading,
  subNav,
  activeHeading,
  setActiveHeading,
}) => {
  const handleClick = (heading) => {
    setActiveHeading(heading);
    setHeading(heading);
  };

  return (
    <div className="dashboard-subnav-container">
      {subNav.map((data) => (
        <div
          key={data.navHeading}
          onClick={() => handleClick(data.navHeading)}
          className={`subnav-item ${activeHeading === data.navHeading ? "active" : ""}`}
        >
          <div>{data.navHeading}</div>
          <hr
            className={`subnav-hr ${activeHeading === data.navHeading ? "active" : ""}`}
          />
        </div>
      ))}
    </div>
  );
};

export default DashboardSubNavbar;
