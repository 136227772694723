import {
  FETCH_LEARNING_HUB_DATA_SUCCESS,
  FETCH_LEARNING_HUB_DATA_FAILURE,
  UPDATE_VERIFICATION_STATUS_SUCCESS,
  UPDATE_VERIFICATION_STATUS_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  GET_JOB_REFERRAL_BY_ID,
  GET_RESUME_REVIEW_BY_ID,
  GET_JOB_REFERRALS,
  GET_RESUME_REVIEW,
  UPDATE_JOB_SEEKER_FEEDBACK,
  UPDATE_RESUME_REVIWE_FEEDBACK,
  CREATE_JOB_REFERRAL_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SEND_OTP_SUCCESS,
  FORGOT_PASSWORD_SEND_OTP_FAILURE,
  FORGOT_PASSWORD_VERIFY_OTP_SUCCESS,
  FORGOT_PASSWORD_VERIFY_OTP_FAILURE,
  FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE,
} from "../actions/adminAction";

const initialState = {
  admin: [],
  user: [],
  selectedJobReferral: null,
  resumeReview: [],
  selectedResumeReview: null,
  dashboard: null,
  isAuthenticated: false,
  error: null,
  loading: false,
  otpSent: false,
  passwordReset: false,
  referrerVerificationStatus: null,
   learningHubData: [],
};

// Authentication Reducer
const adminReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_LEARNING_HUB_DATA_SUCCESS:
      return {
        ...state,
        loading: false, // Turn off loading indicator
        learningHubData: action.payload, // Set fetched learning hub data
        error: null, // Clear error on success
      };

    case FETCH_LEARNING_HUB_DATA_FAILURE:
      return {
        ...state,
        loading: false, // Turn off loading indicator
        learningHubData: [], // Reset data in case of failure
        error: action.payload, // Set error message
      };
      
    case UPDATE_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        referrerVerificationStatus: action.payload,
        error: null,
      };

    case UPDATE_VERIFICATION_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload, // Set fetched user data
        error: null,
      };

    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload, // Set error message
      };

    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload, // Set users to the data returned by the API
        error: null,
      };

    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload, // Capture the error from the failure action
      };

    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        admin: action.payload,
        isAuthenticated: true,
        error: null,
      };

    case CREATE_ADMIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
        error: null,
      };

    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case GET_JOB_REFERRAL_BY_ID:
      return {
        ...state,
        selectedJobReferral: action.payload,
      };

    case GET_JOB_REFERRALS:
      return {
        ...state,
        admin: action.payload,
      };
    case GET_RESUME_REVIEW:
      return {
        ...state,
        resumeReview: action.payload,
      };
    case UPDATE_JOB_SEEKER_FEEDBACK:
      return {
        ...state,
        admin: state.admin.data.map((admin) =>
          admin._id === action.payload._id
            ? { ...admin, feedback: action.payload.feedback }
            : admin,
        ),
      };
    case GET_RESUME_REVIEW_BY_ID:
      return {
        ...state,
        selectedResumeReview: action.payload,
      };
    case UPDATE_RESUME_REVIWE_FEEDBACK:
      return {
        ...state,
        resumeReview: state.resumeReview.data.map((resumeReview) =>
          resumeReview._id === action.payload._id
            ? { ...resumeReview, feedback: action.payload.feedback }
            : resumeReview,
        ),
      };

    case CREATE_JOB_REFERRAL_REQUEST:
      return {
        ...state,
        admin: [...state.admin, action.payload],
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        admin: action.payload,
        isAuthenticated: true,
        error: null,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        error: null,
      };

    case RESEND_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpSent: false,
        error: null,
      };

    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true,
        error: null,
      };

    case CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_SEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        error: null,
      };

    case FORGOT_PASSWORD_SEND_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpSent: false,
        error: null,
      };

    case FORGOT_PASSWORD_VERIFY_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true,
        error: null,
      };

    case FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default adminReducer;
