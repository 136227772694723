import React, { useState, useRef } from "react";
import "./CreateNewDiscussion.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import ItalicI from "../../assets/images/Italic-i.svg";
import BoldIcon from "../../assets/images/Bold-icon.svg";
import UnderLine from "../../assets/images/UnderLine.svg";
import SIcon from "../../assets/images/S-icon.svg";
import SpaceIcon from "../../assets/images/Space-icon.svg";
import Dot from "../../assets/images/dot-alimnt.svg";
import Num from "../../assets/images/Number-aliment.svg";
import linkIcon from "../../assets/images/Link-icon.svg";
import IMGUpload from "../../assets/images/Img-uploade-icon.svg";

import slace from "../../assets/images/slace-icon.svg";
import cotation from "../../assets/images/cotation-icon.svg";
import line from "../../assets/images/line-icon.svg";

const CreateNewDiscussion = () => {
  return (
    <div className="create-post-container">
      <h4 className="create-post-title">
        Create Post - Start a New Discussion
      </h4>
      <form className="create-post-form">
        <div className="form-group">
          <div className="discussion-input-label">Discussion Title</div>
          <input
            type="text"
            id="title"
            maxLength="300"
            placeholder="Enter your discussion title here"
            className="input-title"
          />
          <div className="character-count">0/300</div>
        </div>
        <div className="form-group">
          <div className="discussion-input-label">Content</div>
          <textarea
            id="content"
            placeholder="What are your thoughts?"
            className="input-content"
          ></textarea>
          <div className="toolbar">
            <button type="button">
              <strong>
                <img className="word-icons" src={BoldIcon}></img>
              </strong>
            </button>
            <button type="button">
              <em>
                <img className="word-icons" src={ItalicI}></img>
              </em>
            </button>
            <button type="button">
              <u>
                <img className="word-icons" src={UnderLine}></img>
              </u>
            </button>
            <button>
              <img className="word-icons" src={SIcon}></img>
            </button>
            <button type="button">
              {<img className="word-icons" src={SpaceIcon}></img>}
            </button>
            <select className="font-select">
              <option value="normal">Normal text</option>
            </select>
            <div className="Number-dot-aliment">
              <div>
                <img className="dot" src={Dot}></img>
              </div>
              <div>
                <img className="dot" src={Num}></img>
              </div>
            </div>

            <button type="button">
              <img className="word-icons" src={linkIcon}></img>
            </button>
            <button>
              <img className="word-icons" src={IMGUpload}></img>
            </button>
            <button type="button">
              <img className="word-icons" src={slace}></img>
            </button>
            <button type="button">
              <img className="word-icons" src={cotation}></img>
            </button>
            <button type="button">
              <img className="word-icons" src={line}></img>
            </button>
          </div>
        </div>
        <div className="form-group">
          <div className="discussion-input-label">Tags</div>
          <input
            type="text"
            id="tags"
            placeholder="Add tags..."
            className="input-tags"
          />
        </div>
        <div className="contact-form-button-container">
          <CustomButton
            label="Cancel"
            onClick={() => console.log("Button clicked!")}
            type="contact-form-button"
          />
          <CustomButton
            label="Publish"
            onClick={() => console.log("Button clicked!")}
            type="contact-form-button-purple"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateNewDiscussion;
