import React from "react";
import people1 from "../../../../assets/images/people1.jpg";
import people2 from "../../../../assets/images/people2.jpg";
import moment from "moment";

const ChatManagerSideBar = ({ chats, onChatSelect }) => {
  console.log("jobReferralChats", chats);

  return (
    <div className="jri-jobs-list">
      {chats?.map((chat) => (
        <div
          key={chat.id}
          className="jri-job-item"
          onClick={() => onChatSelect(chat)}
        >
          <div className="jri-job-avatars">
            <div className="jri-avatar-group">
              <img
                src={chat.img1 || people1}
                alt="Avatar 1"
                className="jri-avatar"
                onError={(e) => e.target.src = 'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o='}

              />

              {chat.chatType === "jobReferral" && (
                <img
                  src={
                    chat.participants?.referrer?.personalInfo?.picture ||
                    people2
                  }
                  onError={(e) => e.target.src = 'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o='}
                  alt="Avatar 2"
                  className="jri-avatar jri-avatar-overlap"
                />
              )}
            </div>
          </div>
          <div className="jri-job-info">
            <h3 className="jri-job-title">
              {chat.participants?.jobSeeker?.personalInfo?.firstName}
            </h3>
            <p className="jri-job-company">
              {chat.latestMessage?.content?.value}
            </p>
          </div>
          <div className="jri-job-meta">
            <span className="jri-job-time">
              {moment(chat.latestMessage?.timestamp).format("MM/DD/YYYY")}
            </span>
            {chat.unreadCount > 0 && (
              <span className="jri-badge">{chat.unreadCount}</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatManagerSideBar;
