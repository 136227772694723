// Learning Hub Action Types
export const FETCH_LEARNINGHUB_DATA_SUCCESS = "FETCH_LEARNINGHUB_DATA_SUCCESS";
export const FETCH_LEARNINGHUB_DATA_FAILURE = "FETCH_LEARNINGHUB_DATA_FAILURE";
export const FETCH_LEARNING_HUB_SUCCESS = "FETCH_LEARNING_HUB_SUCCESS";
export const FETCH_LEARNING_HUB_FAILURE = "FETCH_LEARNING_HUB_FAILURE";

// Action creators for Learning Hub data
export const fetchLearningHubDataSuccess = (data) => ({
    type: FETCH_LEARNINGHUB_DATA_SUCCESS,
    payload: data,
  });
  
  export const fetchLearningHubDataFailure = (error) => ({
    type: FETCH_LEARNINGHUB_DATA_FAILURE,
    payload: error,
  });


  // Action creators for Learning Hub data
export const fetchLearningHubSuccess = (data) => ({
  type: FETCH_LEARNING_HUB_SUCCESS,
  payload: data,
});

export const fetchLearningHubFailure = (error) => ({
  type: FETCH_LEARNING_HUB_FAILURE,
  payload: error,
});
