// Navbar.js
import React, { useEffect, useState } from "react";
import "./Navbar.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import Logo from "../../../assets/images/konect-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import SignInModal from "../../../components/SignInModal/SignInModal";
import GetStartArrow from "../../../assets/images/Get-start-arrow.svg";
import DownArrow from "../../../assets/images/Nav-down-arrow.svg";
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isCreatePassword, setIsCreatePassword] = useState(false);
  const [otpVarification, setOtpVarification] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const isAuthenticated = localStorage.getItem("authToken");

  const [showOptions, setShowOptions] = useState(false);

  const handleButtonClick = () => {
    setShowOptions(!showOptions);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Example of removing an auth token from localStorage
    sessionStorage.removeItem("user"); // Remove user session data if stored in sessionStorage

    // Redirect to the login page or home page
    window.location.href = "/login"; // Adjust the path to your login or home route
  };

  const handleDashboard = () => {
    const user = JSON.parse(localStorage.getItem("user"))?.user;
    const userRole = user?.userRole;

    if (userRole === "admin") {
      navigate("/admin_dashboard");
    } else if (userRole === "jobSeeker") {
      navigate("/user_dashboard");
    } else if (userRole === "referrer") {
      navigate(
        user?.referrerVerification !== null
          ? "/referrer_dashboard"
          : "/referrer_varification",
      );
    } else {
      navigate("/"); // Fallback to home for unknown roles
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setIsLoginModal(false);
    setIsCreatePassword(false);
    setOtpVarification(false);
    setOtp(false);
    setIsForgotPassword(false);
  };

  const handleLoginClick = () => {
    setIsLoginModal(true);
    setIsModalOpen(true);
    setIsForgotPassword(false);
  };
  const handleSignupClick = () => {
    setIsLoginModal(false);
    setIsModalOpen(true);
    setIsForgotPassword(false);
  };

  const handleClick = () => {
    alert("Button clicked!");
  };
  const navigate = useNavigate();
  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const scrollToSection = (id) => {
    // alert(`Navigating to section ${id}`);
    const element = document.getElementById(id);
    if (element) {
      // alert("Scrolling to section");
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
      if (window.innerWidth < 1023) {
        setMenuOpen(false);
      } else {
        setMenuOpen(true);
      }
    };
    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const links = [
    {
      text: "Our Services",
      href: "/ourServices",
      hasArrow: true,
    },
    {
      text: "Learning Hub",
      href: "/learningHub",
      hasArrow: true,
    },
    {
      text: "Discussion Forum",
      href: "/discussionForum",
      hasArrow: false,
    },
    {
      text: "About Us",
      href: "/aboutus",
      hasArrow: false,
    },
    {
      text: "Help",
      href: "/helpcenter",
      hasArrow: true,
    },
  ];

  return (
    <header>
      <nav className="nav-container">
        <div className="navbar-container">
          <div className="logo-container">
            <a href="/" className="logo-link">
              <img src={Logo} alt="Bio World Logo" className="logo-image" />
            </a>
          </div>

          {menuOpen && (
            <ul>
              {/* <div className="navbar-container"> */}
              {links.map((link, index) => (
                <li key={index} className="nav-menu-item">
                  <Link
                    to={link?.href ? link.href : ""}
                    onClick={link?.onClick ? link?.onClick : null}
                    className="nav-link-down-arrow"
                  >
                    {link.text}
                    {/* {link.hasArrow && (
                      <img
                        src={DownArrow}
                        alt="arrow"
                        className="nav-down-arrow"
                      />
                    )} */}
                  </Link>
                </li>
              ))}
              {/* </div> */}

              <li></li>
              {!isAuthenticated ? (
                <li>
                  <CustomButton
                    // label="Get Started →"

                    label={
                      <span className="get-button-aliment">
                        Get Started{" "}
                        <img
                          src={GetStartArrow}
                          alt="arrow"
                          className="get-button-icon"
                        />
                      </span>
                    }
                    onClick={handleSignupClick}
                    type="submit signin-button"
                  />
                  <CustomButton
                    label="Sign In"
                    onClick={handleLoginClick}
                    type="green-border signin-button"
                  />
                </li>
              ) : (
                // <li>
                //   <CustomButton
                //     label="My Account"
                //     onClick={() => {
                //       window.location.href = "/user_dashboard"; // Navigate to the URL
                //     }}
                //     type="green-border signin-button"
                //   />
                // </li>

                <li>
                  {window.innerWidth > 768 ? (
                    <CustomButton
                      label="My Account"
                      onClick={handleButtonClick}
                      type="green-border signin-button"
                    />
                  ) : (
                    // Directly show the options menu on mobile view
                    <div className="options-menu mobile-view">
                      <button className="styled-button" onClick={handleLogout}>
                        Logout
                      </button>
                      <button
                        className="styled-button"
                        onClick={handleDashboard}
                      >
                        Dashboard
                      </button>
                    </div>
                  )}

                  {showOptions && window.innerWidth > 768 && (
                    <div className="options-menu">
                      <button
                        className="styled-button"
                        onClick={handleDashboard}
                      >
                        Dashboard
                      </button>
                      <button className="styled-button" onClick={handleLogout}>
                        Logout
                      </button>
                    </div>
                  )}
                </li>
              )}
            </ul>
          )}
          <div className="menu" onClick={handleMenuClick}>
            {!menuOpen ? (
              <i className="fa-solid fa-bars" style={{ color: "white" }}></i>
            ) : (
              <i class="fa-solid fa-xmark" style={{ color: "white" }}></i>
            )}
          </div>
        </div>
      </nav>
      {/* <KonectModal isOpen={isModalOpen} onClose={closeModal} /> */}
      <SignInModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onClose={closeModal}
        setIsLoginModal={setIsLoginModal}
        isLoginModal={isLoginModal}
        isCreatePassword={isCreatePassword}
        setIsCreatePassword={setIsCreatePassword}
        otpVarification={otpVarification}
        setOtpVarification={setOtpVarification}
        otp={otp}
        setOtp={setOtp}
        isForgotPassword={isForgotPassword}
        setIsForgotPassword={setIsForgotPassword}
      />
    </header>
  );
};

export default Navbar;
