// Action Types for industries and companies
export const FETCH_INDUSTRIES_SUCCESS = "FETCH_INDUSTRIES_SUCCESS";
export const FETCH_INDUSTRIES_FAILURE = "FETCH_INDUSTRIES_FAILURE";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";

export const FETCH_ALL_CHAT_SUCCESS = "FETCH_ALL_CHAT_SUCCESS";
export const OPEN_CHAT_SUCCESS = "OPEN_CHAT_SUCCESS";
export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";

export const CREATE_JOBSEEKER_SUCCESS = "CREATE_JOBSEEKER_SUCCESS";
export const CREATE_JOBSEEKER_FAILURE = "CREATE_JOBSEEKER_FAILURE";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";
export const GET_JOB_REFERRAL_BY_ID = "GET_JOB_REFERRAL_BY_ID";
export const GET_ADMIN_JOB_REFERRAL_BY_ID = "GET_ADMIN_JOB_REFERRAL_BY_ID";
export const GET_ADMIN_RESUME_REVIWE_BY_ID = "GET_ADMIN_RESUME_REVIWE_BY_ID";
export const GET_RESUME_REVIEW_BY_ID = "GET_RESUME_REVIEW_BY_ID";
export const GET_JOB_REFERRALS = "GET_JOB_REFERRALS";
export const FETCH_ADMIN_JOB_REFERRALS = "FETCH_ADMIN_JOB_REFERRALS";
export const FETCH_ADMIN_RESUME_REVIEW = "FETCH_ADMIN_RESUME_REVIEW";
export const GET_RESUME_REVIEW = "GET_RESUME_REVIEW";
export const ADMIN_UPDATE_JOB_REFERRAL_STATUS =
  "ADMIN_UPDATE_JOB_REFERRAL_STATUS";
export const ADMIN_UPDATE_RESUME_REVIEW = "ADMIN_UPDATE_RESUME_REVIEW";
export const UPDATE_JOB_SEEKER_FEEDBACK = "UPDATE_JOB_SEEKER_FEEDBACK";
export const UPDATE_RESUME_REVIWE_FEEDBACK = "UPDATE_RESUME_REVIWE_FEEDBACK";
export const CREATE_JOB_REFERRAL_REQUEST = "CREATE_JOB_REFERRAL_REQUEST";
export const CREATE_RESUME_REVIEW_REQUEST = "CREATE_JOB_REFERRAL_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
export const CREATE_PASSWORD_SUCCESS = "CREATE_PASSWORD_SUCCESS";
export const CREATE_PASSWORD_FAILURE = "CREATE_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_SEND_OTP_SUCCESS =
  "FORGOT_PASSWORD_SEND_OTP_SUCCESS";
export const FORGOT_PASSWORD_SEND_OTP_FAILURE =
  "FORGOT_PASSWORD_SEND_OTP_FAILURE";
export const FORGOT_PASSWORD_VERIFY_OTP_SUCCESS =
  "FORGOT_PASSWORD_VERIFY_OTP_SUCCESS";
export const FORGOT_PASSWORD_VERIFY_OTP_FAILURE =
  "FORGOT_PASSWORD_VERIFY_OTP_FAILURE";
export const FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS =
  "FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE =
  "FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE";

export const fetchIndustriesSuccess = (industries) => ({
  type: FETCH_INDUSTRIES_SUCCESS,
  payload: industries,
});

export const fetchIndustriesFailure = (error) => ({
  type: FETCH_INDUSTRIES_FAILURE,
  payload: error,
});

export const fetchCompaniesSuccess = (companies) => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload: companies,
});

export const fetchCompaniesFailure = (error) => ({
  type: FETCH_COMPANIES_FAILURE,
  payload: error,
});

export const fetchAllChatSuccess = (chats) => ({
  type: FETCH_ALL_CHAT_SUCCESS,
  payload: chats,
});

export const openChatSuccess = (chat) => ({
  type: OPEN_CHAT_SUCCESS,
  payload: chat,
});

export const sendMessageRequest = (message) => ({
  type: SEND_MESSAGE_REQUEST,
  payload: message,
});

// Create JOBSEEKER Success
export const createJobSeekerSuccess = (jobSeeker) => ({
  type: CREATE_JOBSEEKER_SUCCESS,
  payload: jobSeeker,
});

// Create User Failure
export const createJobSeekerFailure = (error) => ({
  type: CREATE_JOBSEEKER_FAILURE,
  payload: error,
});

// Create Dashboard Success
export const fetchDashboardSuccess = (jobSeeker) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: jobSeeker,
});

// Create Dashboard Failure
export const fetchDashboarsdFailure = (error) => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: error,
});

// Get Admin Job Referral
export const fetchAdminJobReferrals = (jobReferrals) => ({
  type: FETCH_ADMIN_JOB_REFERRALS,
  payload: jobReferrals,
});

// Get Admin Resume Review
export const fetchAdminResumeReview = (resumeReview) => ({
  type: FETCH_ADMIN_RESUME_REVIEW,
  payload: resumeReview,
});

// Get Job Referral By Id
export const getJobReferralById = (jobReferral) => ({
  type: GET_JOB_REFERRAL_BY_ID,
  payload: jobReferral,
});

// Get Admin Resume Review By Id
export const getAdminResumeReviewById = (jobReferral) => ({
  type: GET_ADMIN_RESUME_REVIWE_BY_ID,
  payload: jobReferral,
});

// Get Admin Job Referral By Id
export const getAdminJobReferralById = (jobReferral) => ({
  type: GET_ADMIN_JOB_REFERRAL_BY_ID,
  payload: jobReferral,
});

// Update Job Seeker Feedback
export const updateJobSeekerFeedback = (jobSeekerFeedback) => ({
  type: UPDATE_JOB_SEEKER_FEEDBACK,
  payload: jobSeekerFeedback,
});

// Get Job Referrals
export const getJobReferrals = (jobReferrals) => ({
  type: GET_JOB_REFERRALS,
  payload: jobReferrals,
});

// Get Resume Review By Id
export const getResumeReviewById = (resumeReview) => ({
  type: GET_RESUME_REVIEW_BY_ID,
  payload: resumeReview,
});

// Create Job Referral Request
export const createJobReferralRequest = (jobReferral) => ({
  type: CREATE_JOB_REFERRAL_REQUEST,
  payload: jobReferral,
});

// Create Job Referral Request
export const createResumeReviewRequest = (resumeReview) => ({
  type: CREATE_RESUME_REVIEW_REQUEST,
  payload: resumeReview,
});

// Update job referral status
export const adminUpdateJobReferralStatus = (jobReferralStatus) => ({
  type: ADMIN_UPDATE_JOB_REFERRAL_STATUS,
  payload: jobReferralStatus,
});

// Update Resume Review Feedback
export const updateResumeReviewFeedback = (resumeReviewFeedback) => ({
  type: UPDATE_RESUME_REVIWE_FEEDBACK,
  payload: resumeReviewFeedback,
});
// Update Resume Review
export const adminUpdateResumeReview = (resumeReview) => ({
  type: ADMIN_UPDATE_RESUME_REVIEW,
  payload: resumeReview,
});

// Get Resume Review
export const getResumeReview = (resumeReview) => ({
  type: GET_RESUME_REVIEW,
  payload: resumeReview,
});
// Login Success
export const loginSuccess = (jobSeeker) => ({
  type: LOGIN_SUCCESS,
  payload: jobSeeker,
});

// Login Failure
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

// Resend OTP Success
export const resendOTPSuccess = () => ({
  type: RESEND_OTP_SUCCESS,
});

// Resend OTP Failure
export const resendOTPFailure = (error) => ({
  type: RESEND_OTP_FAILURE,
  payload: error,
});

// Verify OTP Success
export const verifyOTPSuccess = () => ({
  type: VERIFY_OTP_SUCCESS,
});

// Verify OTP Failure
export const verifyOTPFailure = (error) => ({
  type: VERIFY_OTP_FAILURE,
  payload: error,
});

// Create Password Success
export const createPasswordSuccess = () => ({
  type: CREATE_PASSWORD_SUCCESS,
});

// Create Password Failure
export const createPasswordFailure = (error) => ({
  type: CREATE_PASSWORD_FAILURE,
  payload: error,
});

// Forgot Password Send OTP Success
export const forgotPasswordSendOTPSuccess = () => ({
  type: FORGOT_PASSWORD_SEND_OTP_SUCCESS,
});

// Forgot Password Send OTP Failure
export const forgotPasswordSendOTPFailure = (error) => ({
  type: FORGOT_PASSWORD_SEND_OTP_FAILURE,
  payload: error,
});

// Forgot Password Verify OTP Success
export const forgotPasswordVerifyOTPSuccess = () => ({
  type: FORGOT_PASSWORD_VERIFY_OTP_SUCCESS,
});

// Forgot Password Verify OTP Failure
export const forgotPasswordVerifyOTPFailure = (error) => ({
  type: FORGOT_PASSWORD_VERIFY_OTP_FAILURE,
  payload: error,
});

// Forgot Password Create Password Success
export const forgotPasswordCreatePasswordSuccess = () => ({
  type: FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS,
});

// Forgot Password Create Password Failure
export const forgotPasswordCreatePasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE,
  payload: error,
});
