import React from 'react';
import './FormLoader.css'
import { useSelector } from 'react-redux';
const FormLoader = () => {
    const loading= useSelector((state)=>state.spinner.loading)

  return (
    loading && ( <div className='form-loader-container'><div class="form-loader"></div></div>)
  );
};

export default FormLoader;
