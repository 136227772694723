import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import SimpleLayout from "./layouts/simpleLayout/SimpleLayout";
import Home from "./Pages/Home";
import OurServices from "./Pages/OurServices";
import AboutUs from "./Pages/AboutUs";
import HelpCenterPage from "./Pages/HelpCenterPage";
import FrequentlyAskedQuestions from "./Pages/FrequentlyAskedQuestions";
import ContactFormPage from "./Pages/ContactFormPage";
import LearningHubPage from "./Pages/LearningHub/LearningHubPage";
import ArticlePage from "./Pages/LearningHub/ArticlePage";
import DiscussionForumPage from "./Pages/DiscussionForumPage/DiscussionForumPage";
import WebinarViewPage from "./Pages/LearningHub/WebinarViewPage";
import CreateDiscussionPage from "./Pages/DiscussionForumPage/CreateDiscussionPage";
import DiscussionPostPage from "./Pages/DiscussionForumPage/DiscussionPostPage";
import ProvideFeedbackPage from "./Pages/ProvideFeedbackPage";
import AdminLayout from "./layouts/AdminLayout";
import AdminDashboard from "./containers/AdminLayout/Dashboard/index";
import AdminSettings from "./containers/AdminLayout/Settings/index";
import DiscussionForm from "./containers/AdminLayout/DiscussionForm";

import UserManagement from "./containers/AdminLayout/UserManagement";
import User from "./containers/AdminLayout/User";
import UserProfile from "./containers/AdminLayout/UserProfile";

import DiscussionFormDetails from "./Pages/AdminLayoutPage/DiscussionFormDetails/index";
import DashboardLearningHub from "./Pages/AdminLayoutPage/DashboardLearningHub";
import LearningHubDetails from "./Pages/AdminLayoutPage/DashboardLearningHub/LearningHubDetails";
import CreatePost from "./Pages/AdminLayoutPage/DashboardLearningHub/CreatePost";
import ServiceManagemant from "./Pages/AdminLayoutPage/ServiceManagemant/ServiceManagemantTables/JobReferralsTable";
import JobReferrals from "./Pages/AdminLayoutPage/ServiceManagemant/ServiceManagemantDetails/JobReferrals/JobReferrals";
import CareerAdvice from "./Pages/AdminLayoutPage/ServiceManagemant/ServiceManagemantDetails/CareerAdvice/CareerAdvice";
import ResumeReview from "./Pages/AdminLayoutPage/ServiceManagemant/ServiceManagemantDetails/ResumeReview/ResumeReview";
import JobReferralsTable from "./Pages/AdminLayoutPage/ServiceManagemant/ServiceManagemantTables/JobReferralsTable";
import CareerAdviceTable from "./Pages/AdminLayoutPage/ServiceManagemant/ServiceManagemantTables/CareerAdviceTable";
import ResumeReviewTable from "./Pages/AdminLayoutPage/ServiceManagemant/ServiceManagemantTables/ResumeReviewTable";
import ChatMessenger from "./Pages/AdminLayoutPage/ChatMessenger/ChatMessenger";
import ChatManager from "./Pages/AdminLayoutPage/ChatManager/ChatManager";
import SignInModal from "./components/SignInModal/SignInModal";
import UserLayout from "./layouts/UserLayout";
import Dashboard from "./Pages/UserLayoutPages/Dashboard/Dashboard";
import UserJobReferrals from "./Pages/UserLayoutPages/JobReferrals/JobReferrals";
import JobReferralsNewRequest from "./Pages/UserLayoutPages/JobReferrals/JobReferralsNewRequest/JobReferralsNewRequest";
import UserJobReferralsDetails from "./Pages/UserLayoutPages/JobReferrals/UserJobReferrals/UserJobReferralsDetails";
import UserResumeReviewTable from "./Pages/UserLayoutPages/ResumeReview/UserResumeReviewTable/UserResumeReviewTable";
import UserResumeReviewDetails from "./Pages/UserLayoutPages/ResumeReview/UserResumeReviewDetails/UserResumeReviewDetails";
import UserSettings from "./Pages/UserLayoutPages/UserSettings/UserSettings";
import ReffererVarificationLayout from "./layouts/ReffererVarificationLayout";
import AccVerification from "./Pages/ReferrerLayoutPage/AccVerification/AccVerification";
import ReferrerInformation from "./Pages/ReferrerLayoutPage/ReferrerInformation/ReferrerInformation";
import RefereeVerification from "./Pages/ReferrerLayoutPage/RefereeVerification/RefereeVerification";
import ReffererLayout from "./layouts/ReffererLayout";
import ReferrerDashboard from "./Pages/ReferrerLayoutPage/ReferrerDashboard/ReferrerDashboard";
import DashboardDetails from "./Pages/ReferrerLayoutPage/DashboardDetails/DashboardDetails";
import ResumeReviewNewRequest from "./Pages/UserLayoutPages/ResumeReview/ResumeReviewNewRequest/ResumeReviewNewRequest";

export default function Router() {
  const isAuthenticated = localStorage.getItem("authToken");
  const userRole = JSON.parse(localStorage.getItem("user"))?.user?.userRole;
  const user = JSON.parse(localStorage.getItem("user"))?.user;
  console.log(userRole, user);

  const routes = useRoutes([
    {
      path: "/admin_dashboard",
      // element: isAuthenticated&&userRole==='admin' ? <AdminLayout /> : <Navigate to="/login" replace />,
      element: true ? <AdminLayout /> : <Navigate to="/login" replace />,
      children: [
        { path: "", element: <AdminDashboard /> },
        { path: "settings", element: <AdminSettings /> },
        { path: "discussion_forum", element: <DiscussionForm /> },
        { path: "user_management", element: <UserManagement /> }, // to do
        {
          path: "discussion_forum_Details",
          element: <DiscussionFormDetails />,
        },
        { path: "learning_hub", element: <DashboardLearningHub /> },
        {
          path: "learning_hub/learning_hub_details",
          element: <LearningHubDetails />,
        },
        { path: "create_post", element: <CreatePost /> },
        { path: "service_management", element: <JobReferralsTable /> },
        { path: "career_advice_table", element: <CareerAdviceTable /> },
        { path: "resume_review", element: <ResumeReviewTable /> },
        { path: "job_referrals/:id", element: <JobReferrals /> },
        { path: "career_advice", element: <CareerAdvice /> },
        { path: "resume_review/:id", element: <ResumeReview /> },
        { path: "chat_manager", element: <ChatManager /> },
        { path: "User_", element: <User /> }, // to do
        { path: "user_management/:id", element: <UserProfile /> }, //to do
      ],
    },
    {
      path: "/user_dashboard",
      element:
        isAuthenticated && userRole === "jobSeeker" ? (
          <UserLayout />
        ) : (
          <Navigate to="/login" replace />
        ),
      children: [
        { path: "", element: <Dashboard /> },
        { path: "job_referrals", element: <UserJobReferrals /> }, //refer this  for get array data
        {
          path: "job_referrals/newrequest",
          element: <JobReferralsNewRequest />, //refer this  for post with files
        },
        { path: "job_referrals/:id", element: <UserJobReferralsDetails /> },
        { path: "resume_review", element: <UserResumeReviewTable /> },
        {
          path: "resume_review/newrequest",
          element: <ResumeReviewNewRequest />,
        },
        { path: "resume_review/:id", element: <UserResumeReviewDetails /> },
        { path: "settings", element: <UserSettings /> },
      ],
    },
    {
      path: "/referrer_dashboard",
      // element:
      // isAuthenticated && userRole === "referrer" ? (
      //   user?.referrerVerification == "active" ? (
      //     <ReffererLayout />
      //   ) : (
      //     <Navigate to="/referrer_varification" replace />
      //   )
      // ) : (
      //   <Navigate to="/login" replace />
      // ),
      element: true ? <ReffererLayout /> : <Navigate to="/login" replace />,
      children: [
        { path: "", element: <ReferrerDashboard /> },
        { path: "referrer_details/:id", element: <DashboardDetails /> },
        { path: "settings/:id", element: <UserSettings /> },
      ],
    },
    {
      path: "/referrer_varification",
      element:
        isAuthenticated && userRole === "referrer" ? (
          <ReffererVarificationLayout />
        ) : (
          <Navigate to="/login" replace />
        ),
      children: [
        { path: "", element: <AccVerification /> },

        { path: "referee_verification", element: <RefereeVerification /> },
        { path: "settings", element: <UserSettings /> },
      ],
    },
    {
      path: "",
      element: <SimpleLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/ourServices", element: <OurServices /> },
        { path: "/aboutus", element: <AboutUs /> },
        { path: "/helpcenter", element: <HelpCenterPage /> },
        { path: "/questions", element: <FrequentlyAskedQuestions /> },
        { path: "/contactForm", element: <ContactFormPage /> },
        { path: "/provideFeedback", element: <ProvideFeedbackPage /> },
        { path: "/learningHub", element: <LearningHubPage /> },
        { path: "/articleview/:id", element: <ArticlePage /> },
        { path: "/discussionForum", element: <DiscussionForumPage /> },
        { path: "/webinarView", element: <WebinarViewPage /> },
        { path: "/createDiscussion", element: <CreateDiscussionPage /> },
        { path: "/discussionPost", element: <DiscussionPostPage /> },
        { path: "/signinmodal", element: <SignInModal /> },
      ],
    },
    { path: "*", element: <Navigate to="/" replace /> },

    // {
    //   path: '/login',
    //   element:<Login/>,
    // },
  ]);

  return routes;
}
