import React, { useEffect, useState } from "react";
import "./CreatePost.css";
import TitlePath from "../../../../components/TitlePath/TitlePath";
import DashboardHeading from "../../../../components/DashboardHeading/DashboardHeading";
import { useNavigate } from "react-router-dom";
import DashboardSubNavbar from "../../../../components/DashboardSubNavbar/DashboardSubNavbar";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import ImageUploadGallery from "../../../../components/ImageUploadGallery/ImageUploadGallery";
import VideoUploadGallery from "../../../../components/ImageUploadGallery/VideoUploadGallery";

const CreatePost = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: `discussion_forum`,
    paths: [{ label: "Dashboard > ", onClick: handleBack }],
  };

  const headingDes = {
    heading: "Learning Hub",
    des: "Oversee content and user progress to ensure a seamless and effective learning experience.",
  };

  const subNav = [{ navHeading: "Article" }, { navHeading: "Webinar" }];

  const [author, setAuthor] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("Article");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([]);
  const [navClick, setNavClick] = useState("Article");
  const [heading, setHeading] = useState();
  const [activeHeading, setActiveHeading] = useState("Article");
  const [formErrors, setFormErrors] = useState({});
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (heading === "Article") {
      setNavClick("Article");
    } else {
      setNavClick("Webinar");
    }
  }, [heading]);

  const validateForm = () => {
    const errors = {};
    if (!author) errors.author = "Author is required.";
    if (!date) errors.date = "Date is required.";
    if (!title) errors.title = "Title is required.";
    if (!content) errors.content = "Content is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form Submitted", {
        author,
        date,
        category,
        title,
        content,
        tags,
        images,
        videos,
      });
      // Handle form submission here
    }
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
        <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      </div>

      <div className="setting-page-container">
        <form className="update-post-form">
          <h4 style={{ fontWeight: 400 }}>Create Post</h4>
          <div className="update-post-form-first-child">
            <div className="form-group">
              <label htmlFor="author">Author</label>
              <input
                type="text"
                placeholder="Enter Author name here"
                id="author"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
              {formErrors.author && (
                <div className="post-error-message">{formErrors.author}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="text"
                id="date"
                placeholder="DD MM YY"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              {formErrors.date && (
                <div className="post-error-message">{formErrors.date}</div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label>Category</label>
            <div className="category-buttons">
              <DashboardSubNavbar
                subNav={subNav}
                setNavClick={setNavClick}
                setHeading={setHeading}
                activeHeading={activeHeading}
                setActiveHeading={setActiveHeading}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="title">Discussion Title</label>
            <input
              type="text"
              id="title"
              placeholder="Enter your discussion title here"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {formErrors.title && (
              <div className="post-error-message">{formErrors.title}</div>
            )}
          </div>

          <div>
            <div className="form-group">
              <label htmlFor="content">Discussion Content</label>
              <textarea
                id="content"
                placeholder="Enter your discussion here"
                value={content}
                onChange={handleContentChange}
                maxLength={300}
                rows={12}
              />
              <div className="text-area-content-length-container">
                {content.length}/300
              </div>
              {formErrors.content && (
                <div className="post-error-message">{formErrors.content}</div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="tags">Tags</label>
            <input
              type="text"
              id="tags"
              placeholder="Add Tag"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setTags([...tags, e.target.value]);
                  e.target.value = "";
                }
              }}
            />
            <div className="tags-container">
              {tags.map((tag, index) => (
                <span key={index} className="tag">
                  #{tag}
                  <button
                    type="button"
                    onClick={() => setTags(tags.filter((_, i) => i !== index))}
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>
          {activeHeading == "Article" ? (
            <div className="form-group">
              <label>Image</label>
              <ImageUploadGallery setImages={setImages} images={images} />
            </div>
          ) : (
            <div className="form-group">
              <label>Video</label>
              <VideoUploadGallery setVideos={setVideos} videos={videos} />
            </div>
          )}

          <div className="form-actions">
            <CustomButton
              label="Publish"
              type="submit"
              onClick={handleSubmit}
            />
            <CustomButton
              label="Save as Draft"
              onClick={() => console.log("Save as Draft clicked!")}
              type="dashboard-gray-button"
            />
            <CustomButton
              label="Cancel"
              onClick={handleBack}
              type="dashboard-gray-button"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePost;
