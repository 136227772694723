import React, { useState } from "react";
import "./DashboardTable.css";
import FillerByIcon from "../../assets/images/adminLayout/Dashboard/Icons/FillerByIcon.svg";
import SortByIcon from "../../assets/images/adminLayout/Dashboard/Icons/SortByIcon.svg";
import doubleArrowRight from "../../assets/images/doubleArrowRight.svg";
import singleArrowRight from "../../assets/images/singleArrowRight.svg";
import moment from "moment";

const DashboardTable = ({
  tableData,
  tabs,
  activeTab,
  setActiveTab,
  tableHeading,
  handleViewClick,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div>
      <div className="tabs">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`tab ${activeTab === tab.name ? "active" : ""}`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name} <span className="tab-count">{tab.count}</span>
          </button>
        ))}
      </div>

      <div className="search-filter-container">
        <div>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input className="table-search-input" placeholder="Search post" />
        </div>
        <div>
          <button className="filter-sort-button">
            <img src={FillerByIcon} className="filter-sort-button-icon" />
            Filter by
          </button>
          <button className="filter-sort-button">
            {" "}
            <img src={SortByIcon} className="filter-sort-button-icon" />
            Sort by
          </button>
        </div>
      </div>
      <div className="blog-post-table">
        <table>
          <thead>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              {tableHeading.map((data) => (
                // <th></th>
                // <th>Post ID</th>
                // <th>Title</th>
                // <th>Author</th>
                // <th>Date Created</th>
                // <th>Status</th>
                // <th>Action</th>
                // <th>{data.heading}</th>
                <th key={data.key}>{data.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* {currentItems.map((post) => (
              <tr key={post.id}>
                <td>
                  <input type="checkbox" />
                </td>
                <td>{post.id}</td>
                <td>{post.title}</td>
                <td>{post.author}</td>
                <td>{post.dateCreated}</td>
                <td>
                  <span className={`status ${post.status.toLowerCase()}`}>
                    {post.status}
                  </span>
                </td>
                <td>
                  <div className="table-button-container">
                    <button className="action-btn delete">
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                    <button className="action-btn edit">
                      <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))} */}
            {currentItems.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => handleViewClick(row.id)}>
                <td>
                  <input type="checkbox" />
                </td>
                {tableHeading.map((column) => (
                  <td key={column.key}>
                    {column.key === "action" ? (
                      <button className="action-btn edit">
                        <i className="fa-regular fa-pen-to-square"></i>
                      </button>
                    ) : column.key === "status" ||
                      column.key === "currentStatus" ? (
                      <span
                        className={`status ${row[column.key].toLowerCase()}`}
                      >
                        {row[column.key].charAt(0).toUpperCase() +
                          row[column.key].slice(1).toLowerCase()}
                      </span>
                    ) : column.key === "submissionDate" ? (
                      moment(row[column.key]).format("DD MMM YYYY")
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="previous-next-button-icon-container">
          <button
            className="pagination-arrow-button"
            onClick={() => paginate(1)}
            disabled={currentPage === 1}
          >
            <img src={doubleArrowRight} className="previous-next-button-img" />
          </button>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-arrow-button"
          >
            <img src={singleArrowRight} className="previous-next-button-img" />
          </button>
        </div>
        <div>
          {[...Array(totalPages).keys()].map((number) => (
            <button
              key={number + 1}
              onClick={() => paginate(number + 1)}
              className={number + 1 === currentPage ? "active" : ""}
            >
              {number + 1}
            </button>
          ))}
        </div>
        <div className="previous-next-button-icon-container">
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-arrow-button"
          >
            <img
              src={singleArrowRight}
              className="previous-next-button-img"
              style={{ transform: "scaleX(-1)" }}
            />
          </button>
          <button
            className="pagination-arrow-button"
            onClick={() => paginate(totalPages)}
            disabled={currentPage === totalPages}
          >
            <img
              src={doubleArrowRight}
              className="previous-next-button-img"
              style={{ transform: "scaleX(-1)" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardTable;
