import React, { useEffect, useState } from "react";
import "./ResumeReview.css";
import TitlePath from "../../../../../components/TitlePath/TitlePath";
import { useNavigate, useParams } from "react-router-dom";
import chatIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import resumeIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/resumeIcon.svg";
import coverLetterIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/coverLetterIcon.svg";
import createIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/createIcon.svg";
import featureSearch from "../../../../../assets/images/adminLayout/Dashboard/Icons/featureSearch.svg";
import checkIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/checkIcon.svg";
import arrowBack from "../../../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import saveIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/saveIcon.svg";
import autorenew from "../../../../../assets/images/adminLayout/Dashboard/Icons/autorenew.svg";
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  adminUpdateResumeReviewAysn,
  getAdminResumeReview,
} from "../../../../../services/jobSeeker/jobSeekerService";
import FileUpload from "../../../../../components/FileUpload/FileUpload";

const ResumeReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState("");
  const [feedback, setFeedback] = useState("");
  const [file, setFile] = useState(null);

  const resumeReviewDetail = useSelector(
    (state) => state.jobSeeker.selectedAdminResumeReview || null
  );

  const handleResumeUpload = (file) => {
    console.log("Resume Uploaded:", file);
    setFile(file);
  };

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);

    const response = await dispatch(
      adminUpdateResumeReviewAysn(id, {
        status: newStatus,
      })
    );
    console.log(response.data?.statusCode);

    if (response?.data?.statusCode === 200 && response?.data?.data?._id) {
      dispatch(getAdminResumeReview(id));
    }
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSave = async () => {
    if (feedback && file) {
      dispatch(
        adminUpdateResumeReviewAysn(id, {
          resume: file,
          feedback: feedback,
          status: status,
        })
      );
    }
  };

  useEffect(() => {
    if (id) {
      console.log("Dispatching getJobReferral with ID:", id);
      dispatch(getAdminResumeReview(id));
    }
  }, [dispatch, id]);

  const handleChatBack = () => {
    navigate(`/admin_dashboard/resume_review`);
  };

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: `Service Management`,
    paths: [{ label: "Dashboard >", onClick: handleBack }],
  };
  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="jra-container">
        {/* Header */}
        <div className="jra-header">
          <div className="jra-header-title">
            {/* <span>←</span> */}
            <img
              src={arrowBack}
              className="jra-header-title-icon"
              onClick={handleChatBack}
            />
            <h4>
              Job Referrals -{" "}
              <span className="green-heading">
                {resumeReviewDetail?.jobSeekerDetails?.name} -{" "}
                {resumeReviewDetail?.index}
              </span>
            </h4>
          </div>
          <div className="jra-header-actions">
            <select
              className="jra-status-select"
              value={status}
              onChange={handleStatusChange}
            >
              <option value="" disabled className="status-option">
                Mark Status
              </option>
              <option value="completed" className="status-option completed">
                Mark it as Completed
              </option>
              <option value="pending" className="status-option pending">
                Mark it as Pending
              </option>
            </select>

            <button className="jra-chat-button">
              <img src={chatIcon} className="chatIcon" />
              Chat
              <span className="jra-notification-badge">15</span>
            </button>
          </div>
        </div>
        {/* Application Status */}
        <h5 className="jra-heading">Application Status</h5>
        <div className="jra-card">
          <div className="jra-status-row">
            <div>
              <p className="jra-status-label">Name</p>
              <p className="jra-status-value">
                {" "}
                {resumeReviewDetail?.jobSeekerDetails?.name}
              </p>
            </div>
            <div>
              <div>
                <p className="jra-status-label">Submission Date</p>
                <p className="jra-status-value">
                  {moment(resumeReviewDetail?.submissionDate).format(
                    "YYYY-MM-DD"
                  )}
                </p>
              </div>
              <div>
                <p className="jra-status-label">Status</p>
                <span className="jra-status-badge">
                  {" "}
                  {resumeReviewDetail?.currentStatus
                    ? resumeReviewDetail.currentStatus.charAt(0).toUpperCase() +
                      resumeReviewDetail.currentStatus.slice(1).toLowerCase()
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* User Information */}
        <h5 className="jra-heading">User Information</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">E-Mail Address</p>
              <p className="jra-status-value">
                {resumeReviewDetail?.jobSeekerDetails?.email}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number</p>
              <p className="jra-status-value">
                {" "}
                {resumeReviewDetail?.jobSeekerDetails?.mobile}
              </p>
            </div>
          </div>
        </div>
        {/* Industry Selection */}
        <h5 className="jra-heading">Industry Selection</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Industry</p>
              <p className="jra-status-value">
                {" "}
                {resumeReviewDetail?.industryDetails?.industry}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Desired Position</p>
              <p className="jra-status-value">
                {" "}
                {resumeReviewDetail?.industryDetails?.desiredPosition}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Desired Company</p>
              <p className="jra-status-value">
                {" "}
                {resumeReviewDetail?.industryDetails?.desiredCompany}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Current Job</p>
              <p className="jra-status-value">
                {resumeReviewDetail?.industryDetails?.currentJob}
              </p>
            </div>
          </div>
        </div>
        {/* Application Details */}
        <h5 className="jra-heading">Application Details</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Resume</p>
              <a
                href={`https://konect.sktgold.com/${resumeReviewDetail?.resume}`}
                target="_blank"
                className="jra-file-link "
              >
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={resumeIcon} />
                {`https://konect.sktgold.com/${resumeReviewDetail?.resume}`}
              </a>
            </div>
            <div>
              <p className="jra-status-label">Cover Letter</p>
              <a
                href={`https://konect.sktgold.com/${resumeReviewDetail?.coverLetter}`}
                target="_blank"
                className="jra-file-link "
              >
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={coverLetterIcon} />
                {`https://konect.sktgold.com/${resumeReviewDetail?.coverLetter}`}{" "}
              </a>
            </div>
          </div>
        </div>
        {/* AI-Review */}
        <div className="jra-heading-container">
          <h5 className="jra-heading">AI-Review</h5>
          <button className="jra-save-button">
            <img src={autorenew} className="save-icon" />
            Reinitiate AI Review
          </button>
        </div>
        <div className="jra-card">
          <div className="jra-grid-2-col ai-review-container">
            <div>
              {/* <p className="jra-status-label">AI-powered Reviewed CV</p>
              <a href="#" className="jra-file-link ">
                <img src={resumeIcon} />
                AI-powered Reviewed CV.pdf
              </a> */}
              <p className="jra-status-label">AI-powered Reviewed CV</p>
              <p className="jra-status-label-colour">
                <div className="jra-grid-1-col">
                  {resumeReviewDetail?.review?.aiReviewedFeedback
                    ?.split("\n")
                    .map((line, index) => {
                      line = line.trim();

                      if (line.match(/^(\d+)\./)) {
                        const parts = line.split(":");
                        return (
                          <p key={index}>
                            <strong>{parts[0]}:</strong> {parts[1]}
                          </p>
                        );
                      }

                      if (line !== "") {
                        return <p key={index}>{line}</p>;
                      }

                      return null;
                    })}
                </div>
              </p>
            </div>
            {/* <div>
              <p className="jra-status-label">Feedback</p>
              <textarea
                className="feedback-ai-input-container"
                id="feedbackAI"
                placeholder="Enter your feedback"
                // value={feedbackAI}
                maxLength={300}
                rows={20}
              />
            </div> */}
          </div>
        </div>
        {/* Consultant-Review*/}
        <div className="jra-heading-container">
          <h5 className="jra-heading">Consultant-Review</h5>
          <button className="jra-save-button" onClick={handleSave}>
            <img src={saveIcon} className="save-icon" />
            Save
          </button>
        </div>

        <div className="jra-card">
          <div className="jra-grid-2-col ai-review-container">
            <p className="jra-status-label">CV Consultant Reviewed CV</p>
            <div className="cv-consultant-review-child">
              <a
                href={`https://konect.sktgold.com/${resumeReviewDetail?.review?.consultantReviewedResume}`}
                target="_blank"
                className="jra-file-link "
              >
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={coverLetterIcon} />
                {`https://konect.sktgold.com/${resumeReviewDetail?.review?.consultantReviewedResume}`}{" "}
              </a>
              {/* <CustomButton
                label="Upload"
                onClick={handleResumeUpload}
                type="contact-form-button gray-square-button"
              /> */}
              <div className="upload-section">
                <FileUpload
                  fileHeading="Upload"
                  uploadDes="Drag and Drop or Choose your resume to upload"
                  onFileSelect={handleResumeUpload}
                  isRequired={false}
                />
              </div>
            </div>
            <div>
              <p className="jra-status-label">Feedback</p>
              {/* <textarea
                className="feedback-ai-input-container"
                id="feedbackAI"
                placeholder="Enter your feedback"
                // value={feedbackAI}
                maxLength={300}
                rows={20}
              /> */}
              <textarea
                className="feedback-ai-input-container"
                placeholder="Enter your feedback"
                value={feedback}
                onChange={handleFeedbackChange}
                maxLength={300}
                rows={5}
              />
            </div>
          </div>
        </div>
        {/* Payment Details */}
        <h5 className="jra-heading">Payment Details</h5>
        <div className="jra-card">
          <div className="payment-received-details">
            <h3 className="payment-subtitle">Payment Received Details</h3>
            <div className="payment-row">
              <span className="payment-label">Reference</span>
              <span className="payment-value">
                {resumeReviewDetail?.payment?.index}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Date</span>
              <span className="payment-value">
                {moment(resumeReviewDetail?.payment?.date).format("YYYY-MM-DD")}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Method</span>
              <span className="payment-value">
                {" "}
                {resumeReviewDetail?.payment?.paymentMethod}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Amount</span>
              <span className="payment-value">
                {resumeReviewDetail?.payment?.amount}
              </span>
            </div>
          </div>
        </div>
        {/* Status Timeline */}
        <h5 className="jra-heading">Status</h5>
        <div className="jra-card">
          <div className="jra-timeline">
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={createIcon} />
              <div className="jra-timeline-label">Created</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>

            <hr className="jra-timeline-career-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={featureSearch} />
              <div className="jra-timeline-label">Review Started</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>

            <hr className="jra-timeline-career-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={checkIcon} />
              <div className="jra-timeline-label">Completed</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResumeReview;
