import React, { useState, useEffect } from "react";
import "./DashboardLearningHub.css";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../components/TitlePath/TitlePath";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { fetchLearningHubData } from "../../../services/admin/adminService";
import { useSelector, useDispatch } from "react-redux";
import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";

const DashboardLearningHub = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const { learningHubData, loading, error } = useSelector((state) => state.admin);
  const data = learningHubData || []; // Fallback to empty array if no data

  const [activeTab, setActiveTab] = useState("All");

  const tabs = [
    { name: "All", count: 84 },
    { name: "Article", count: 39 },
    { name: "Webinar", count: 33 },
    { name: "Published", count: 33 },
    { name: "Draft", count: 51 },
    { name: "Restricted", count: 21 },
  ];

  const columns = [
    { key: "contentId", label: "Content ID" },
    { key: "title", label: "Title" },
    { key: "category", label: "Category", type: "category", sortable: true },
    { key: "author", label: "Author" },
    { key: "datePublished", label: "Date Published" },
    { key: "status", label: "Status", type: "status", sortable: true },
  ];

  // Fetch data when the component mounts
  useEffect(() => {
    dispatch(fetchLearningHubData());
  }, [dispatch]);

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: `discussion_forum`,
    paths: [
      { label: "Dashboard > ", onClick: handleBack },
    ],
  };

  const headingDes = {
    heading: "Learning Hub",
    des: "Oversee content and user progress to ensure a seamless and effective learning experience.",
  };

  // Map the API data to the format used in the table
  const mappedData = data.map(item => ({
    contentId: item.index,
    title: item.title,
    category: item.category.charAt(0).toUpperCase() + item.category.slice(1), // Capitalize category
    author: item.author,
    datePublished: new Date(item.publishedAt).toLocaleDateString(), // Format date
    status: item.status.charAt(0).toUpperCase() + item.status.slice(1), // Capitalize status
  }));

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath
          exactPath={titlePath.exactPath}
          paths={titlePath.paths}
          showNotification={true}
        />
         <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
        <div className="sub-setting-page-container blog-post-table-container">
          <div className="dashboard-table-heading">
            <h4>Overview</h4>
          </div>
          {/* Pass the mapped data to CustomTable */}
          <CustomTable
            columns={columns}
            data={mappedData} // Pass the formatted data
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardLearningHub;
