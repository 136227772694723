import React from "react";
import LearningHubFilter from "../../containers/LearningHub/LearningHubFilter";
import LearningHub from "../../containers/LearningHub/learningHub";

const LearningHubPage = () => {
  return (
    <>
      <div className="main-header-heading-condatiner">
        <h1>Learning Hub</h1>
        <p>
          Empowering Your Career Journey with Webinars, Articles, and Quizzes
        </p>
      </div>
      <div className="main-home-container">
        <LearningHubFilter />
        <LearningHub />
      </div>
    </>
  );
};

export default LearningHubPage;
