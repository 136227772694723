import React, { useState } from "react";
import "./DiscussionForumFilter.css";
import searchIcon from "../../assets/images/searchIcon.svg";
import clockIcon from "../../assets/images/clockIcon.svg";
import fireIcon from "../../assets/images/fireIcon.svg";

const DiscussionForumFilter = () => {
  const [selectedRadio, setSelectedRadio] = useState("CareerAdvice");

  const handleChange = (event) => {
    setSelectedRadio(event.target.value);
    // setActiveQuestion(null);
    // if (event.target.value === "general") {
    //   setQuestionData(general);
    // }
    // if (event.target.value === "jobSeekers") {
    //   setQuestionData(jobSeekers);
    // }
    // if (event.target.value === "referrers") {
    //   setQuestionData(referrers);
    // }
    // if (event.target.value === "payments") {
    //   setQuestionData(payments);
    // }
    // if (event.target.value === "privacyAndSecurity") {
    //   setQuestionData(privacyAndSecurity);
    // }
  };
  return (
    <div className="discussion-fourm-filter-container">
      <div className="help-center-search-container">
        <div className="discussion-forum-search">
          <img
            className="search-icon-help-center"
            src={searchIcon}
            alt="search icon"
          />
          <input
            className="discussion-forum-search-input"
            placeholder="What do you want to ask?"
          />
        </div>
      </div>
      <div>
        <h4>Konect Community Forum</h4>
        <p className="articleview-des">
          Welcome to the heart of the Konect community! This forum is your go-to
          place for engaging with fellow job seekers and industry professionals.
          Whether you’re looking for advice, sharing your experiences, or
          seeking inspiration, you’ll find a supportive network here.
        </p>
        <div className="discussion-button-container">
          <button className="discussion-button">
            {" "}
            <img className="discussion-button-icon" src={clockIcon} />
            Most Recent
          </button>
          <button className="discussion-button">
            <img className="discussion-button-icon-fire" src={fireIcon} />
            Most Popular
          </button>
        </div>
      </div>

      <div className="questions-filter-container">
        <div>Category</div>
        <div className="filter-radio-button-container">
          <label
            style={{
              color: selectedRadio === "CareerAdvice" ? "#1AD079" : "inherit",
            }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="CareerAdvice"
              // checked={selectedRadio === "general"}
              onChange={handleChange}
            />
            Career Advice
          </label>
          <label
            style={{
              color:
                selectedRadio === "ResumeCoverLetterTips"
                  ? "#1AD079"
                  : "inherit",
            }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="ResumeCoverLetterTips"
              // checked={selectedRadio === "jobSeekers"}
              onChange={handleChange}
            />
            Resume & Cover Letter Tips
          </label>
          <label
            style={{
              color:
                selectedRadio === "InterviewPreparation"
                  ? "#1AD079"
                  : "inherit",
            }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="InterviewPreparation"
              // checked={selectedRadio === "referrers"}
              onChange={handleChange}
            />
            Interview Preparation
          </label>
          <label
            style={{
              color:
                selectedRadio === "NetworkingStrategies"
                  ? "#1AD079"
                  : "inherit",
            }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="NetworkingStrategies"
              // checked={selectedRadio === "payments"}
              onChange={handleChange}
            />
            Networking Strategies
          </label>
          <label
            style={{
              color: selectedRadio === "CompanyInsights" ? "#1AD079" : "",
            }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="CompanyInsights"
              // checked={selectedRadio === "privacyAndSecurity"}
              onChange={handleChange}
            />
            Company Insights
          </label>
          <label
            style={{
              color: selectedRadio === "SuccessStories" ? "#1AD079" : "",
            }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="SuccessStories"
              // checked={selectedRadio === "privacyAndSecurity"}
              onChange={handleChange}
            />
            Success Stories
          </label>
          <label
            style={{
              color: selectedRadio === "GeneralDiscussion" ? "#1AD079" : "",
            }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="GeneralDiscussion"
              // checked={selectedRadio === "privacyAndSecurity"}
              onChange={handleChange}
            />
            General Discussion
          </label>
        </div>
      </div>
    </div>
  );
};

export default DiscussionForumFilter;
