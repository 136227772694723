import React, { useEffect, useState } from "react";
import "./ReferrerDashboard.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import addCircleIcon from "../../../assets/images/UserLayout/Icons/add_circle_icon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchReferralDashboardAyns } from "../../../services/referrer/referrerService";

export default function ReferrerDashboard() {
  const dispatch = useDispatch();

  const resumeReviews = useSelector(
    (state) => state.referrer.referralResumeReview
  );

  console.log("resume review : ", resumeReviews);

  useEffect(() => {
    dispatch(fetchReferralDashboardAyns());
  }, [dispatch]);

  const columns = [
    { key: "id", label: "ID" },
    { key: "userName", label: "User Name" },
    { key: "jobTitle", label: "Job Title" },
    { key: "company", label: "Company" },
    { key: "submissionDate", label: "Submission Date" },
    { key: "status", label: "Status", type: "status", sortable: true },
  ];

  const handleEditClick = (id) => {
    navigate(`/referrer_dashboard/referrer_details/${id}`);
  };
  // Map resume review to table data format
  const mappedData =
    resumeReviews &&
    resumeReviews?.data?.length > 0 &&
    resumeReviews?.data.map((item) => ({
      id: item.index,
      _id: item.id,
      userName: item.userName,
      jobTitle: item.jobTitle,
      company: item.company,
      submissionDate: new Date(item.submissionDate).toLocaleDateString(),
      status: item.status,
    }));

  const tabs = [
    { name: "All", count: 84 },
    { name: "Approved", count: 39 },
    { name: "Pending", count: 33 },
  ];
  const navigate = useNavigate();

  const handleRequestClick = () => {
    navigate("/user_dashboard/job_referrals/newrequest");
  };
  return (
    <div className="main-user-dashboard-table-container">
      <div className="user-dashboard-table-heading-container">
        <div>
          <h4 className="user-dashboard-table-main-heading">
            Referral Resumes
          </h4>
          <p className="user-dashboard-table-des">
            Find the best talented candidates for your company’s requirements.
          </p>
        </div>
      </div>
      <div className="user-dashboard-table-container">
        <CustomTable
          columns={columns}
          data={mappedData}
          tabs={tabs}
          // setActiveTab={setActiveTab}
          // activeTab={activeTab}
          handleEditClick={handleEditClick}
        />
      </div>
    </div>
  );
}
