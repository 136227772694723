import React from "react";
import "./DiscussionsContainer.css";
import discussionsImg1 from "../../assets/images/discussionsImg1.jpg";
import discussionsImg2 from "../../assets/images/discussionsImg2.jpg";
import discussionsImg3 from "../../assets/images/discussionsImg3.jpg";
import discussionsImg4 from "../../assets/images/discussionsImg4.jpg";
import discussionsImg5 from "../../assets/images/discussionsImg5.jpg";
import discussionsImg6 from "../../assets/images/discussionsImg6.jpg";
import discussionsImg7 from "../../assets/images/discussionsImg7.jpg";
import discussionsImg8 from "../../assets/images/discussionsImg8.jpg";
import discussionsImg9 from "../../assets/images/discussionsImg9.jpg";
import discussionsImg10 from "../../assets/images/discussionsImg10.jpg";
import upArrow from "../../assets/images/arrow-badge.svg";
import downArrow from "../../assets/images/arrow-down-badge.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";

const DiscussionsContainer = () => {
  const discussionData = [
    {
      img: discussionsImg1,
      no: 24,
      name: "Posted by Alex Carl",
      date: "27 April 2024",
      heading:
        "What Are the Best Strategies for Networking at Industry Events?",
      des: "I'm attending my first major industry conference next month and I want to make the most of the networking opportunities. What are some effective strategies for connecting with professionals at these events? How can I make a lasting impression and build meaningful relationships? Any advice or personal experiences would be greatly appreciated!",
    },
    {
      img: discussionsImg2,
      no: 24,
      name: "Posted by Mitchell Marsh",
      date: "03 May 2024",
      heading: "How to Negotiate Salary After Receiving a Job Offer?",
      des: "I’ve just received a job offer and I’m thrilled, but I believe the salary offered is lower than I expected. How should I approach salary negotiations without jeopardizing the offer? What are some strategies to effectively negotiate for a better salary and benefits?",
    },
    {
      img: discussionsImg3,
      no: 24,
      name: "Posted by Mitchell Marsh",
      date: "Yesterday",
      heading: "How to Handle Job Rejections Positively?",
      des: "I've recently faced a few job rejections and it's been quite discouraging. I'm trying to stay positive and motivated, but it's tough. How do you cope with job rejections and turn them into learning experiences? Any advice on maintaining confidence and perseverance during the job search process?",
    },
    {
      img: discussionsImg4,
      no: 24,
      name: "Posted by Ricky Ponting",
      date: "14 January 2023",
      heading: "Tips for Acing Virtual Job Interviews",
      des: "With many companies now conducting interviews online, I want to ensure I'm well-prepared for virtual interviews. What are some key tips and best practices for succeeding in a virtual job interview? How can I create a professional setup at home and effectively communicate my skills and experiences over video?",
    },
    {
      img: discussionsImg5,
      no: 24,
      name: "Posted by Kundu Manga",
      date: "3 hrs ago",
      heading: "How to Tailor Your Resume for Different Job Applications?",
      des: "I'm currently applying to several different companies and I want to make sure my resume stands out. I've heard that tailoring your resume for each job application is crucial, but I'm not sure how to do it effectively. Can anyone share tips or strategies on how to customize my resume for different roles and industries?",
    },
    {
      img: discussionsImg6,
      no: 24,
      name: "Posted by Alex Carl",
      date: "27 April 2024",
      heading:
        "What Are the Best Strategies for Networking at Industry Events?",
      des: "I'm attending my first major industry conference next month and I want to make the most of the networking opportunities. What are some effective strategies for connecting with professionals at these events? How can I make a lasting impression and build meaningful relationships? Any advice or personal experiences would be greatly appreciated!",
    },
    {
      img: discussionsImg7,
      no: 24,
      name: "Posted by Mitchell Marsh",
      date: "03 May 2024",
      heading: "How to Negotiate Salary After Receiving a Job Offer?",
      des: "I’ve just received a job offer and I’m thrilled, but I believe the salary offered is lower than I expected. How should I approach salary negotiations without jeopardizing the offer? What are some strategies to effectively negotiate for a better salary and benefits?",
    },
    {
      img: discussionsImg8,
      no: 24,
      name: "Posted by Mitchell Marsh",
      date: "Yesterday",
      heading: "How to Handle Job Rejections Positively?",
      des: "I've recently faced a few job rejections and it's been quite discouraging. I'm trying to stay positive and motivated, but it's tough. How do you cope with job rejections and turn them into learning experiences? Any advice on maintaining confidence and perseverance during the job search process?",
    },
    {
      img: discussionsImg9,
      no: 24,
      name: "Posted by Ricky Ponting",
      date: "14 January 2023",
      heading: "Tips for Acing Virtual Job Interviews",
      des: "With many companies now conducting interviews online, I want to ensure I'm well-prepared for virtual interviews. What are some key tips and best practices for succeeding in a virtual job interview? How can I create a professional setup at home and effectively communicate my skills and experiences over video?",
    },
    {
      img: discussionsImg10,
      no: 24,
      name: "Posted by Kundu Manga",
      date: "3 hrs ago",
      heading: "How to Tailor Your Resume for Different Job Applications?",
      des: "I'm currently applying to several different companies and I want to make sure my resume stands out. I've heard that tailoring your resume for each job application is crucial, but I'm not sure how to do it effectively. Can anyone share tips or strategies on how to customize my resume for different roles and industries?",
    },
  ];

  const navigate = useNavigate();

  const handleViewDiscussionPost = () => {
    navigate("/discussionPost");
  };

  return (
    <div className="main-discussions-container">
      <div>
        {discussionData.map((data) => (
          <div className="sub-discussions-container">
            <div className="discussions-img-container">
              <img
                className="discussions-img"
                src={data.img}
                alt="discussions-image"
              />
            </div>
            <div className="discussions-number-container">
              <img className="up-down-arrow" src={upArrow} />
              <div>24</div>
              <img className="up-down-arrow" src={downArrow} />
            </div>

            <div className="discussions-details-container">
              <div>
                <div className="learninghub-name-date-container">
                  <div>{data.name}</div>
                  <div>{data.date}</div>
                </div>
                <h5 onClick={handleViewDiscussionPost}>{data.heading}</h5>
                <p className="articleview-des discussion-des">{data.des}</p>
              </div>
              <div className="comment-share-button-container">
                <CustomButton
                  label="21 Comments"
                  onClick={() => console.log("Button clicked!")}
                  type="comment-share-button"
                />
                <CustomButton
                  label="8 Shares"
                  onClick={() => console.log("Button clicked!")}
                  type="comment-share-button"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <CustomButton
        label="Show More Discussions"
        onClick={() => console.log("Button clicked!")}
        type="submit"
      />
    </div>
  );
};

export default DiscussionsContainer;
