import React from "react";
import MissionAndVision from "../containers/AboutUs/MissionAndVision";
import Journey from "../containers/AboutUs/Journey";
import Principles from "../containers/AboutUs/Principles";
import AboutPeople from "../containers/AboutUs/AboutPeople";
import UpdatedWith from "../containers/OurServices/UpdatedWith";

const AboutUs = () => {
  return (
    <>
      <div className="main-header-heading-condatiner">
        <h1>About Konect</h1>
        <p>
          Learn about our journey, values, and the passionate team driving our
          mission
        </p>
      </div>
      <div className="main-home-container">
        <MissionAndVision />
        <Journey />
        <Principles />
        {/* <AboutPeople/> */}
        <UpdatedWith />
      </div>
    </>
  );
};

export default AboutUs;
