import React, { useState } from "react";
import "./Header.css";
import headerImage from "../../assets/images/landing-content-img-right.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import GetStartArrow from "../../assets/images/Get-start-arrow.svg";
import { useNavigate } from "react-router-dom";
import SignInModal from "../../components/SignInModal/SignInModal";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isCreatePassword, setIsCreatePassword] = useState(false);
  const [otpVarification, setOtpVarification] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const isAuthenticated = localStorage.getItem("authToken");

  const [showOptions, setShowOptions] = useState(false);

  const navigate = useNavigate();
  const handleSignupClick = () => {
    setIsLoginModal(false);
    setIsModalOpen(true);
    setIsForgotPassword(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setIsLoginModal(false);
    setIsCreatePassword(false);
    setOtpVarification(false);
    setOtp(false);
    setIsForgotPassword(false);
  };
  return (
    <div className="main-landing-header-container">
      <div className="landing-header-container">
        <div className="landing-header-detail-container">
          <div>
            <h1>Stand Out to</h1>
            <h1>
              <span className="heading-different-color">Your Recruiter</span>{" "}
              with Internal{" "}
              <span className="heading-different-color">Referrals</span>
            </h1>
          </div>
          <div className="landing-header-des">
            Don't just apply, get noticed! Our platform connects you with ideal
            referral partners, while expert career advice can be combined with
            resume and cover letter assistance to give your application an extra
            edge.
          </div>
          <div className="landing-header-button-container">
            <CustomButton
              // label="Get Started →"

              label={
                <span className="get-button-aliment">
                  Get Started{" "}
                  <img
                    src={GetStartArrow}
                    alt="arrow"
                    className="get-button-icon"
                  />
                </span>
              }
              onClick={() => handleSignupClick()}
              type="submit"
            />
            <CustomButton
              label="Learn More"
              onClick={() => navigate("/aboutus")}
              type="green-border"
            />
          </div>
        </div>
        {/* <div className="landing-header-image-container"> */}
        <img
          className="landing-header-image"
          src={headerImage}
          alt="landing-header-image"
        />
        {/* </div> */}
      </div>
      <SignInModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onClose={closeModal}
        setIsLoginModal={setIsLoginModal}
        isLoginModal={isLoginModal}
        isCreatePassword={isCreatePassword}
        setIsCreatePassword={setIsCreatePassword}
        otpVarification={otpVarification}
        setOtpVarification={setOtpVarification}
        otp={otp}
        setOtp={setOtp}
        isForgotPassword={isForgotPassword}
        setIsForgotPassword={setIsForgotPassword}
      />
    </div>
  );
};

export default Header;
