// import React , { useState, useEffect } from "react";
// import "./LearningHubArticleView.css";
// import CustomButton from "../../components/CustomButton/CustomButton";
// import learninghubImg1 from "../../assets/images/learninghubImg1.svg";
// import articalBackArrow from "../../assets/images/artical-back-arrow.svg";
// import { useNavigate } from "react-router-dom";

// import learningleftarrow from "../../assets/images/learningHub-articleView-left-arrow.svg";
// import learningrightarrow from "../../assets/images/learningHub-articleView-right-arrow.svg";
// import { useSelector, useDispatch } from "react-redux";
// import { fetchLearningHubById } from "../../services/LearningHub/LearningHub";

// const LearningHubArticleView = () => {
//   const navigate = useNavigate();

//    const dispatch = useDispatch();
   
//      // Redux state
//      const { learningData, loading, error } = useSelector((state) => state.learninghub);


//   const articleData = {
//     img: learninghubImg1,
//     name: "Mitchell Johnson",
//     date: "27 April 2024",
//     heading: "Mastering Job Interviews: Tips from Top Recruiters",
//     des: "Landing a job interview is a significant step in your career journey, but mastering the interview itself is where the real challenge lies. To help you succeed, we’ve gathered insights from top recruiters. Before you step into the interview room, make sure you know the company inside out. Top recruiters emphasize the importance of understanding the company’s mission, values, products, and recent achievements. This knowledge demonstrates your genuine interest and helps you tailor your responses to align with the company’s goals.",
//   };

//   const tags = [
//     {
//       tag: "Job Interview",
//     },
//     {
//       tag: "Guidance",
//     },
//     {
//       tag: "Talent Acquisition ",
//     },
//   ];
//   return (
//     <div className="main-articleview-container">
//       <div className="articleview-back-button-container">
//         <CustomButton
//           // label="Back to Learning Hub"
//           label={
//             <span className="btn-arrow-aliment">
//               <img
//                 src={articalBackArrow}
//                 alt=""
//                 style={{ width: "20px", marginRight: "8px" }}
//               />
//               Back to Learning Hub
//             </span>
//           }
//           onClick={() => navigate("/learningHub")}
//           type="backto-button"
//         />
//       </div>
//       <div className="articleview-img-container">
//         <img
//           className="articleview-image"
//           src={articleData.img}
//           alt="article-image"
//         />
//       </div>
//       <div className="articleview-des-container">
//         <div className="learninghub-name-date-container">
//           <div>{articleData.name}</div>
//           <div>{articleData.date}</div>
//         </div>
//         <h3 className="articleview-heading">{articleData.heading}</h3>
//         <p className="articleview-des">{articleData.des}</p>

//         <div className="tag-container">
//           <p className="articleview-des">Tags</p>
//           {tags.map((data) => (
//             <CustomButton
//               label={data.tag}
//               onClick={() => console.log("Button clicked!")}
//               type="tag-button"
//             />
//           ))}
//         </div>
//         <div className="pre-next-article-container">
//           <div className="pre-article-container">
//             <div className="previous-post">Previous Post</div>
//             <div>
//               {/* <i class="fa-solid fa-arrow-left"></i> */}
//               <img
//                 className="learningHub-arrow-props"
//                 src={learningleftarrow}
//               ></img>
//               <div>
//                 Creating Impactful Resumes and Cover Letters to Stand Out Higher
//                 in the Industry World
//               </div>
//             </div>
//           </div>
//           <div className="pre-article-container">
//             <div className="next-post">Next Post</div>
//             <div>
//               <div>
//                 Building a Strong Professional Network Among the Fostering World
//               </div>
//               {/* <i class="fa-solid fa-arrow-right"></i> */}
//               <img
//                 className="learningHub-arrow-props"
//                 src={learningrightarrow}
//               ></img>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LearningHubArticleView;

import React, { useState, useEffect } from "react";
import "./LearningHubArticleView.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import learninghubImg1 from "../../assets/images/learninghubImg1.svg";
import articalBackArrow from "../../assets/images/artical-back-arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import learningleftarrow from "../../assets/images/learningHub-articleView-left-arrow.svg";
import learningrightarrow from "../../assets/images/learningHub-articleView-right-arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import { fetchLearningHubById } from "../../services/LearningHub/LearningHub";

const LearningHubArticleView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();

  // Redux state 
  const { learningData, loading, error } = useSelector((state) => state.learninghub);
console.log(learningData);

  useEffect(() => {
    // Dispatch the action to fetch learning hub by ID
    // debugger
    dispatch(fetchLearningHubById(id));
  }, [dispatch, id]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  if (error) {
    return <div>Error: {error.message || 'Something went wrong!'}</div>; // Show error message properly
  }

  if (!learningData) {
    return <div>No data found.</div>; // Handle no data state
  }

  // Assuming 'learningData' has the same structure as the API response
  const articleData = {
    img: learninghubImg1, // This can be updated based on the API response
    name: learningData.author,
    date: new Date(learningData.publishedAt).toLocaleDateString(),
    heading: learningData.title,
    des: learningData.content,
  };

  // Use fallback to empty array if tags are not available
  const tags = (learningData?.tags || []).map((tag) => ({ tag }));

  return (
    <div className="main-articleview-container">
      <div className="articleview-back-button-container">
        <CustomButton
          label={
            <span className="btn-arrow-aliment">
              <img
                src={articalBackArrow}
                alt="back arrow"
                style={{ width: "20px", marginRight: "8px" }}
              />
              Back to Learning Hub
            </span>
          }
          onClick={() => navigate("/learningHub")}
          type="backto-button"
        />
      </div>
      <div className="articleview-img-container">
        <img
          className="articleview-image"
          src={articleData.img}
          alt="article-image"
        />
      </div>
      <div className="articleview-des-container">
        <div className="learninghub-name-date-container">
          <div>{articleData.name}</div>
          <div>{articleData.date}</div>
        </div>
        <h3 className="articleview-heading">{articleData.heading}</h3>
        <p className="articleview-des">{articleData.des}</p>

        <div className="tag-container">
          <p className="articleview-des">Tags</p>
          {tags.map((data, index) => (
            <CustomButton
              key={index}
              label={data.tag}
              onClick={() => console.log("Button clicked!")}
              type="tag-button"
            />
          ))}
        </div>

        <div className="pre-next-article-container">
          <div className="pre-article-container">
            <div className="previous-post">Previous Post</div>
            <div>
              {learningData.previousPost ? (
                <div>
                  <i className="fa-solid fa-arrow-left"></i>
                  <div>{learningData.previousPost.title}</div>
                </div>
              ) : (
                <div>No previous post</div>
              )}
            </div>
          </div>
          <div className="pre-article-container">
            <div className="next-post">Next Post</div>
            <div>
              {learningData.nextPost ? (
                <div>
                  <div>{learningData.nextPost.title}</div>
                  <i className="fa-solid fa-arrow-right"></i>
                </div>
              ) : (
                <div>No next post</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default LearningHubArticleView;
