import React, { useState } from "react";
import "./ContactForm.css";
import mobileIcon from "../../assets/images/callIcon.svg";
import mailIcon from "../../assets/images/mailIcon.svg";
import locationIcon from "../../assets/images/locationIcon.svg";
import facebookIcon from "../../assets/images/facebookIcon.svg";
import linkdinIcon from "../../assets/images/linkdinIcon.svg";
import instaIcon from "../../assets/images/instaIcon.svg";
import youtubeIcon from "../../assets/images/youtubeIcon.svg";
import twiterIcon from "../../assets/images/twiterIcon.svg";
import CustomButton from "../../components/CustomButton/CustomButton";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }
    if (!formData.subject.trim()) newErrors.subject = "Subject is required.";
    if (!formData.message.trim()) newErrors.message = "Message is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log("Form submitted successfully:", formData);
      // Perform submission logic here (e.g., send data to a server)
    }
  };

  const contactDetails = [
    { icon: mobileIcon, details: "+1-234-567-890" },
    { icon: mailIcon, details: "support@konect.com" },
    {
      icon: locationIcon,
      details: "123 Konect Street, Suite 456, City, Country",
    },
  ];

  const socialMediaIcon = [
    { icon: facebookIcon },
    { icon: linkdinIcon },
    { icon: instaIcon },
    { icon: youtubeIcon },
    { icon: twiterIcon },
  ];

  return (
    <div className="main-contact-form-container">
      <div className="contact-details-container">
        <div>
          <div className="services-sub-heading">Get in Touch with Us</div>
          <div className="des-paragraph">
            We’re here to assist you! Whether you have inquiries, feedback, or
            need support, our team is ready to respond promptly and effectively.
          </div>
        </div>

        <div>
          <div className="contact-form-sub-heading">Need More Help?</div>
          {contactDetails.map((data, index) => (
            <div className="contact-icon-details-container" key={index}>
              <img
                className="contact-details-icon"
                src={data.icon}
                alt="contact icon"
              />
              <div>{data.details}</div>
            </div>
          ))}
        </div>

        <div>
          <div className="contact-form-sub-heading">Follow Us</div>
          <div className="contact-social-media-container">
            {socialMediaIcon.map((data, index) => (
              <img
                className="contact-social-media"
                src={data.icon}
                alt="social media icon"
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="contact-form-container">
        <div>
          <div className="services-sub-heading">Contact Form</div>
          <div className="des-paragraph">
            Please fill out the form below, and we’ll respond as soon as
            possible.
          </div>
        </div>

        <div>
          <div className="contact-form-sub-heading">Name</div>
          <input
            className="contact-form-input"
            placeholder="Enter your full name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          {errors.name && <div className="error-message">{errors.name}</div>}

          <div className="contact-form-sub-heading">E-Mail Address</div>
          <input
            className="contact-form-input"
            placeholder="Enter your email address"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && <div className="error-message">{errors.email}</div>}

          <div className="contact-form-sub-heading">Subject</div>
          <input
            className="contact-form-input"
            placeholder="Enter the subject of your inquiry"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
          />
          {errors.subject && (
            <div className="error-message">{errors.subject}</div>
          )}

          <div className="contact-form-sub-heading">Message</div>
          <textarea
            className="contact-form-textarea"
            placeholder="What are your thoughts?"
            rows="8"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />
          {errors.message && (
            <div className="error-message">{errors.message}</div>
          )}

          <div className="contact-form-button-container">
            <CustomButton
              label="Cancel"
              onClick={() => console.log("Cancel clicked!")}
              type="contact-form-button"
            />
            <CustomButton
              label="Send Message"
              onClick={handleSubmit}
              type="contact-form-button-purple"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
