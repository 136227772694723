import React from "react";
import "./AboutUs.css";
import missionIcon from "../../assets/images/mission-png.svg";
import vissionIcon from "../../assets/images/vision-png.svg";

const MissionAndVision = () => {
  const missionVissionData = [
    {
      icon: missionIcon,
      heading: "Mission Statement",
      des: " At Konect, our mission is to bridge the gap in job referrals,providing equal opportunities for all job seekers through meaningful connections.",
    },
    {
      icon: vissionIcon,
      heading: "Vision Statement",
      des: "We envision a world where everyone has access to their dream job, supported by a network of professionals who believe in their potential.",
    },
  ];
  return (
    <div className="about-us-container">
      <div className="about-us-heading">
        Our Mission <span className="heading-different-color">& Vision</span>
      </div>
      <p>Empowering Job Seekers and Connecting Talent with Opportunities</p>
      <div className="main-mission-vission-container">
        {missionVissionData.map((data) => (
          <div className="mission-vission-container">
            <div>
              {" "}
              <img
                className="mission-vission-icon"
                src={data.icon}
                alt="mission-vission-icon"
              />
              <div>
                <div>{data.heading}</div>
                <div>{data.des}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MissionAndVision;
